import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { concat, defer, observable, Observable, of, throwError } from 'rxjs';
import { map, catchError, switchMap, finalize, concatMap, tap, retryWhen, concatMapTo } from 'rxjs/operators';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';
import { AppAuthService } from '../auth/app-auth.service';

@Injectable()
export class ApiAuth0Interceptor implements HttpInterceptor {
  @BlockUI() blockUI!: NgBlockUI;

  constructor(private router: Router,
              private auth: AppAuthService,
              private toastr: ToastrService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.blockUI.start();

    let nextheader = req.headers;

    // トークン取得
    const setToken = this.auth.getToken()
    .pipe(
      tap(token => { nextheader = nextheader.set('Authorization', `Bearer ${token}`); }),
      catchError(result => {
        console.log(Date.now(), 'intercept token error', result);
        this.toastr.error('トークン取得に失敗', 'ポップアップブロックなど見直してください。');
        return of(null);
      })
    );

    // ユーザーID取得
    const setUserId = this.auth.userId()
    .pipe(
      tap(id => { nextheader = nextheader.set('x-new-pos-user', id); })
    );

    // HTTPリクエストを投げる
    const request = of(nextheader).pipe(concatMap(x => {
      const clone = req.clone({ headers: nextheader });
      return next.handle(clone).pipe(catchError(e => this.handleError(e)));
    }));

    const ret = concat(setToken, setUserId, request).pipe(finalize(() => {
      this.blockUI.stop()
    }));

    return ret;
  }

  /**
   * HTTP エラーハンドリング
   * @param error
   * @returns
   */
  private handleError(error: HttpErrorResponse) {
    // Grobal Error Handle
    if (error.status === 404) {
      // 不明なURL
      this.router.navigate(['']);

    } else if (error.status === 401) {
      // 認証エラー
      // ログアウト
      this.auth.logout();

    } else if (error.status === 412) {
      // アクセス拒否 (メンテナンス中)
      this.router.navigate(['maintenance']);

    } else if (error.status === 422) {
      // クライアント側要因の実行エラー
      const blob = error.error as Blob;
      if (blob) {
        blob.text().then(x => {
          this.toastr.error(x);
        });
      } else {
        this.toastr.error(error.message);
      }
    } else if (error.status >= 500) {
      // サーバ内エラー
      this.toastr.error(error.message, '通信エラー発生');
    } else {
      // 処理外のステータス
      this.toastr.error('システムエラー発生');
    }

    return throwError(error);
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { RouterService } from 'src/app/services/router/router.service';
import { environment } from 'src/environments/environment';
import { LogService } from 'src/app/services/log/log.service';
import { LogModel, LogSeverityType } from 'src/app/services/log/log.model';
import { SolveUnmatchedGoodsService } from '../solve-unmatched-goods/solve-unmatched-goods.service';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {
  @ViewChild('downloadLinkRef') public downloadLinkRef: ElementRef;

  constructor(
    private routerService: RouterService,
    private toastr: ToastrService,
    private logService: LogService,
    private solveUnmatchedGoodsService: SolveUnmatchedGoodsService
    ) { }

  ngOnInit(): void {
  }

  public testWriteLog() {
    let log: LogModel = new LogModel();
    log.LogSeverity = 'Info';
    log.LogText = 'Test';
    this.logService.writeLog(log);
  }

  public testToastr() {
    this.toastr.info('ここにメッセージ', 'タイトルだよ');
  }

  public testDownload() {
    this.solveUnmatchedGoodsService.downloadGoodsList('file.csv', 'file', this.downloadLinkRef);
  }
}

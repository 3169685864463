export const environment_org = {
  organization: 'AB',
  auht0_redirectUri: {
    local: 'https://localhost:4200/login',
    dev: 'https://ab.front.g23-newpos-dev.net/login',
    stg: 'https://ab.front.stg.g23-newpos.net/login',
    prod: 'https://ab.front.pro.g23-newpos.net/login'
  },
  tableau_view: {
    new_goods: {
      dev: '/views/AB/sheet0',
      stg: '/views/AB/sheet0',
      prod: '',
    }
  },
  servicenow: {
    masterMaintenanceURL: '?id=sc_cat_item&table=sc_cat_item&sys_id=c6e05df5dba541108a043346f39619a2',
    convertGoodsAttributeURL: '?id=sc_cat_item&table=sc_cat_item&sys_id=887b6a41dbad81100aa3f165f39619f3',
    solveUnmatchedGoodsURL: '?id=sc_cat_item&sys_id=e9d6b75ddbe101108a043346f39619ab&sysparm_category=b9943636db5949100aa3f165f396193c',
    solveUnmatchedStoresURL: '?id=sc_cat_item&sys_id=b9e1215bdbeb01508a043346f3961932&sysparm_category=b9943636db5949100aa3f165f396193c',
    storeMasterMaintenanceURL: '?id=sc_cat_item&sys_id=65cc4506dbf5cd108a043346f39619c9&sysparm_category=b9943636db5949100aa3f165f396193c&catalog_id=-1',
    individualAuthorityURL: '?id=sc_cat_item&sys_id=5287ebb1dbb30d500aa3f165f3961958&sysparm_category=b9943636db5949100aa3f165f396193c',
    requestChainStaffURL: '?id=sc_cat_item&sys_id=f5322fc2dbe5c5100aa3f165f39619d1&sysparm_category=b9943636db5949100aa3f165f396193c&catalog_id=-1',
    requestForChainURL: '?id=sc_cat_item&sys_id=269a3345db65c1100aa3f165f39619a2&sysparm_category=b9943636db5949100aa3f165f396193c',
    generalURL: '?id=sc_cat_item&sys_id=4aa8924edb61c5100aa3f165f396198b&sysparm_category=b9943636db5949100aa3f165f396193c',
    POSRequestURL: '?id=sc_cat_item&table=sc_cat_item&sys_id=d6961886dbff49508a043346f3961996',
    recoveryRequestURL: '?id=sc_cat_item&table=sc_cat_item&sys_id=f23a7892dbbf89508a043346f3961991',
    inquiryURL: '?id=contact_sc_cat_item&sys_id=d68e3b79db5185100aa3f165f39619dc&sysparm_category=76cdd713db3370100aa3f165f3961982'
  }
};

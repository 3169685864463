<div class="main px-3 py-3">
  <div class="page-title-bg">
  </div>
  <div class="page-title">
    <div class="title-container">チェーン参照権限申請</div>
  </div>
  <mat-tab-group class="page-body mat-tab-fill-height" animationDuration="0ms">
    <mat-tab label="申請" class="label">
      <div class="tab-body">
        <div *ngIf="scopes?.showButtonDownloadReferenceAuthority" class="heading">チェーン参照権限をダウンロードする</div>
        <div class="ps-4 mt-3">
          <div class="spinner" *ngIf="downloading">
            <mat-spinner [diameter]="35"></mat-spinner>
          </div>
          <button *ngIf="scopes?.showButtonDownloadReferenceAuthority" [disabled]="downloading" mat-raised-button
            color="primary" (click)="clickedDownloadChainStaff()">
            <i class="fa fa-download"></i>
            権限情報ダウンロード
          </button>
        </div>
        <div class="heading">チェーン参照権限を申請する</div>
        <div class="ps-4 mt-3">
          <button class="apply-button" mat-raised-button color="accent" (click)="openRequest()">
            申請ページを開く
          </button>
          <button mat-raised-button color="primary" (click)="openHistory()">
            申請状況確認
          </button>
        </div>
      </div>
    </mat-tab>
    <mat-tab
      *ngIf="scopes?.showTabAutoAuthorizationSettingAB || scopes?.showTabAutoAuthorizationSettingASD"
      label="自動付与設定" class="label">
      <!-- 自動付与設定 -->
      <div class="auto-set-field">
        <app-auto-authorization-setting-ab *ngIf="scopes?.showTabAutoAuthorizationSettingAB"
          style="width: 100%;">
        </app-auto-authorization-setting-ab>
        <app-auto-authorization-setting-asd *ngIf="scopes?.showTabAutoAuthorizationSettingASD"
          style="width: 100%;">
        </app-auto-authorization-setting-asd>
      </div>
    </mat-tab>
    <mat-tab *ngIf="scopes?.showTabDirectAuthorizationSetting" label="一括変更" class="label">
      <!-- 一括変更 -->
      <div class="direct-set-field">
        <app-direct-authorization-setting-ab style="width: 100%;">
        </app-direct-authorization-setting-ab>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<!-- 非表示ダウンロードリンク -->
<a #downloadLinkRef style="display: none">非表示リンク</a>

import { Component, OnInit } from '@angular/core';
import { MaintenanceMenuService } from 'src/app/shared/maintenance-menu/maintenance-menu.service';

@Component({
  selector: 'app-main-container',
  templateUrl: './main-container.component.html',
  styleUrls: ['./main-container.component.scss']
})
export class MainContainerComponent implements OnInit {

  constructor(public maintenanceMenuService: MaintenanceMenuService) { }

  ngOnInit(): void {
  }

  /**
   * メニュー表示
   */
   public showMenu() {
    this.maintenanceMenuService.showMenu();
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ElementRef, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileDownloadService } from 'src/app/services/file-download/file-download.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UpdateStoresService {
  constructor(
    private http: HttpClient,
    private dl: FileDownloadService) { }

  /**
   * 店舗情報ファイル取り込みAPI呼び出し
   * @param file フォームデータ
   */
  public uploadStoreMaster(file: FormData) {
    let httpOptions: any = {
      // ヘッダ情報
      headers: new HttpHeaders({
        'contet-type': 'multipart/form-data',
      })
    };

    return this.http.post(environment.apiEndPoint + 'api/update-stores/import-file', file, httpOptions) as Observable<any>;
  }

  /**
   * 店舗情報ファイル取り込みAPI呼び出し
   * @param file フォームデータ
   */
   public uploadStoreMasterUnmatched(file: FormData) {
    let httpOptions: any = {
      // ヘッダ情報
      headers: new HttpHeaders({
        'contet-type': 'multipart/form-data',
      })
    };

    return this.http.post(environment.apiEndPoint + 'api/update-stores/upload-unmatched-list', file, httpOptions) as Observable<any>;
  }

  /**
   * 画面初期化処理
   */
  public initialize(organization: string): Observable<any> {
    const httpOptions: any = {
      params: { 'organization': organization }
    };
    return this.http.get(environment.apiEndPoint + 'api/update-stores/initialize', httpOptions) as Observable<any>;
  }

  /** 店舗マスタダウンロード */
  public downloadStoreMaster(param: any, dlname: string, el: ElementRef) {
    this.dl.post('api/update-stores/download-master', param, dlname, el);
  }

  /** アンマッチ店舗有無チェック */
  public checkUnmatchedStoresListExist(param: any): Observable<any> {
    return this.http.post(environment.apiEndPoint + 'api/update-stores/check-unmatched-list-exist', param) as Observable<any>;
  }

  /** アンマッチ店舗ファイルダウンロード */
  public downloadUnmatchedStoresList(param: any, dlname: string, el: ElementRef) {
    this.dl.post('api/update-stores/download-unmatched-list', param, dlname, el);
  }

  /** 店舗申請ファイルダウンロード */
  public downloadApplyFile(param: any, dlname: string, el: ElementRef) {
    this.dl.post('api/update-stores/download-input-form', param, dlname, el);
  }

  /** 店舗マスタアップロード結果ファイルダウンロード */
  public downloadResultFile(fileName: string, dlname: string, el: ElementRef) {
    this.dl.get('api/update-stores/download-result-file', { 'fileName': fileName }, dlname, el);
  }
}

import { Component, OnInit, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { RouterService } from 'src/app/services/router/router.service';
import { MaintenanceMenuService } from 'src/app/shared/maintenance-menu/maintenance-menu.service';
import { environment } from 'src/environments/environment';
import { ScopeViewModel } from 'src/app/services/authority/authority.service.viewmodel';
import { AuthorityService } from 'src/app/services/authority/authority.service';
import { InidividualAuthorityService } from '../individual-authority/inidividual-authority.service';
@Component({
  selector: 'app-set-reference-controls',
  templateUrl: './set-reference-controls.component.html',
  styleUrls: ['./set-reference-controls.component.scss']
})
export class SetReferenceControlsComponent implements OnInit, AfterViewInit {
  @ViewChild('downloadLinkRef') public downloadLinkRef: ElementRef;

  private FileData: FormData;
  public organization: string;

  /** 権限スコープ */
  public scopes: ScopeViewModel;

  /** true:ダウンロード中 */
  public downloading = false;

  constructor(
    public maintenanceMenuService: MaintenanceMenuService,
    private routerService: RouterService,
    private authorityService: AuthorityService,
    private inidividualAuthorityService: InidividualAuthorityService
  ) { }

  ngOnInit(): void {
    this.getScopes();
    this.routerService.setPreviousPage();
    this.organization = environment.organization;
  }

  private async getScopes() {
    this.scopes = await this.authorityService.scopes();
  }

  ngAfterViewInit(): void {
  }

  public getFile(file: FormData) {
    this.FileData = file;
  }

  public uploadFile() {
    // this.FileDataをバックエンドに送信する
  }

  /** 参照権限ダウンロード */
  public clickedDownloadChainStaff() {
    this.inidividualAuthorityService.downloadChainStaff(environment.organization, '参照権限一覧', this.downloadLinkRef);
  }

  public openRequest() {
    window.open(environment.servicenow.requestChainStaffURL);
  }

  public openHistory() {
    window.open(environment.servicenow.myRequestsURL);
  }
}

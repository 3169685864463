import { Component, OnInit } from '@angular/core';
import { AppAuthService } from 'src/app/services/auth/app-auth.service';
import { RouterService } from 'src/app/services/router/router.service';
import { environment } from 'src/environments/environment';

export const DEBUG_AUTHORITY_STORAGE_KEY: string = 'debug.authority'

@Component({
  selector: 'app-authority-selector',
  templateUrl: './authority-selector.component.html',
  styleUrls: ['./authority-selector.component.scss']
})
export class AuthoritySelectorComponent implements OnInit {
  public authories = [];
  public authority = '';

  constructor(
    private auth: AppAuthService,
    private router: RouterService
  ) { }

  ngOnInit(): void {
    if (environment.debugMenu !== true) {
      this.router.navigateByUrl('');
      return;
    }

    this.setAuthories();
  }

  /** 権限一覧設定 */
  private async setAuthories() {
    const user = await this.auth.userId().toPromise();
    this.authories = [
      { display: user, value: user },
      { display: 'チェーン担当G', value: 'debug.chain_manager' },
      { display: 'チェーン本部担当G', value: 'debug.chain_admin' },
      { display: '地区POS担当G', value: 'debug.pos_admin' },
      { display: '主管部G', value: 'debug.admin' },
      { display: 'AIC業務委託G', value: 'debug.business_agent' },
      { display: 'POS利用権限のみ', value: 'debug.chain_none' },
      { display: 'チェーン参照権限あり', value: 'debug.chain_staff' },
      { display: '権限無し', value: 'debug.none' },
      { display: '開発者', value: 'debug.developer' },
    ];

    const storage = localStorage.getItem(DEBUG_AUTHORITY_STORAGE_KEY);

    this.authority = storage ?? user;
  }

  /** 権限設定をクリアする */
  public async clearAuthority() {
    localStorage.removeItem(DEBUG_AUTHORITY_STORAGE_KEY);
    window.location.reload();
  }

  /** 権限設定を有効にする */
  public setAuthority() {
    if (!this.authority) {
      return;
    }

    localStorage.setItem(DEBUG_AUTHORITY_STORAGE_KEY, this.authority);
    window.location.reload();
  }
}

<!-- 自動付与設定 -->
<div class="auto-set-field">
  <!-- 組織別付与 -->
  <div class="contents-container">
    <div class="pb-3">
      設定した組織へ自動的に参照権限を付与します<br>
      ダウンロードファイルを開き、権限付与したい組織のコードを入力してアップロードしてください
    </div>
    <div class="py-2 px-2 example-field">
      <div>
        設定例：Aチェーンの権限を付与したい組織のコードを入力する
      </div>
      <img class="image" src="../../../assets/images/ASD自動付与設定_組織別付与.svg">
    </div>
    <div class="py-3 download-field">
      <div>
        チェーン担当者別設定ファイル：
      </div>
      <div style="margin-left: 0.5rem;">
        <button mat-raised-button color="primary" (click)="downloadConfigFile()">ダウンロード</button>
      </div>
    </div>
    <div class="upload-field">
      <div class="message-title">
        メッセージ
      </div>
      <div class="upload-control-filed">
        <app-message-field class="message-field" #messageField></app-message-field>
        <app-upload-file class="file-drop-field" (fileEvent)="selectedOrganizationConfig($event)"></app-upload-file>
        <div class="upload-button">
          <button mat-raised-button (click)="uploadOrganization()">
            <i class="fa fa-upload"> アップロード</i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- 非表示ダウンロードリンク -->
<a #downloadLinkRef style="display: none">非表示リンク</a>

import { Component, OnInit } from '@angular/core';
import { UploadResultModel, MessageModel } from './message-field.viewmodel';

@Component({
  selector: 'app-message-field',
  templateUrl: './message-field.component.html',
  styleUrls: ['./message-field.component.scss'],
})
export class MessageFieldComponent implements OnInit {
  public messageList = [];

  constructor() {}

  ngOnInit(): void {  }

  /**
   * メッセージ追加処理
   */
  public pushMessage(model: UploadResultModel) {
    this.messageList.unshift(model);
  }
}

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { RouterService } from 'src/app/services/router/router.service';
import { MaintenanceMenuService } from 'src/app/shared/maintenance-menu/maintenance-menu.service';
@Component({
  selector: 'app-set-maintenance-controls',
  templateUrl: './set-maintenance-controls.component.html',
  styleUrls: ['./set-maintenance-controls.component.scss']
})
export class SetMaintenanceControlsComponent implements OnInit, AfterViewInit {
  private FileData: FormData;

  constructor(
    public maintenanceMenuService: MaintenanceMenuService,
    private routerService: RouterService
  ) { }

  ngOnInit(): void {
    this.routerService.setPreviousPage();
  }

  ngAfterViewInit(): void {
  }

  public getFile(file: FormData) {
    this.FileData = file;
  }

  public uploadFile() {
    // this.FileDataをバックエンドに送信する
  }
}

<div class="main px-3 py-3">
  <div class="page-title-bg">
  </div>
  <div class="page-title">
    <div class="title-container">店舗アンマッチダウンロード・申請</div>
  </div>
  <mat-tab-group class="page-body mat-tab-fill-height" animationDuration="0ms">
    <mat-tab label="ダウンロード／申請" class="label">
      <app-download-unmatched-store></app-download-unmatched-store>
    </mat-tab>
    <mat-tab label="店舗アンマッチ アップロード" *ngIf="scopes?.showTabUploadUnmatchedStores" class="label">
      <app-upload-unmatched-store></app-upload-unmatched-store>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="maintenance-message">
  <div class="main-area border border-light">
    <h2>
      <i class="fa fa-wrench" style="color: #ff7500">
        ただいまメンテナンス中です
      </i>
    </h2>
    <div class="mt-4">メンテナンス終了までしばらくお待ちください</div>
  </div>
</div>

<div class="menu-component">
  <div class="close-menu-container">
    <button class="close-menu" (click)="closeMenu()">
      <i class="fas fa-bars"></i>
      メニューを閉じる
    </button>
  </div>
  <!-- <mat-selection-list [multiple]="false" class="pos-menu-selection-list">
    <div *ngFor="let item of menuItems">
      <div *ngIf="item.visible">
        <div mat-subheader class="menu-title">
          <i class="fas fa-shopping-cart"></i>
          {{item.title}}
        </div>
        <div *ngFor="let opt of item.options">
          <mat-list-option *ngIf="opt.visible" class="menu-item" (click)="changedMenuItem(opt.path)"
            [value]="opt.path" [selected]="opt.initSelected">
            {{ opt.title }}
          </mat-list-option>
        </div>
      </div>
    </div>
  </mat-selection-list> -->
  <mat-selection-list [multiple]="false" class="pos-menu-selection-list">
    <div *ngFor="let item of menuItems">
      <div *ngIf="item.visible">
        <div mat-subheader class="menu-title">
          <i class="fas fa-shopping-cart"></i>
          {{item.title}}
        </div>
        <div *ngFor="let opt of item.options">
          <mat-list-option *ngIf="opt.visible" class="menu-item" (click)="opt.func()" [value]="opt.path"
            [selected]="opt.initSelected">
            {{ opt.title }}
          </mat-list-option>
        </div>
      </div>
    </div>
  </mat-selection-list>
  <div class="menu-footer"></div>
</div>

<!-- 非表示ダウンロードリンク -->
<a #downloadLinkRef style="display: none">非表示リンク</a>

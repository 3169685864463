<!-- 自動付与設定 -->
<mat-radio-group aria-label="Select an option" [(ngModel)]="isChain">
  <mat-radio-button class="mat-radio-button" color="primary" value="1" [value]="true">チェーン担当者別付与</mat-radio-button>
  <mat-radio-button class="mat-radio-button" color="primary" value="2" [value]="false">組織別付与</mat-radio-button>
</mat-radio-group>
<!-- チェーン担当者別付与 -->
<div *ngIf="isChain" class="contents-container">
  <div class="pb-3">
    設定した担当区分へ自動的に参照権限を付与します<br>
    ダウンロードファイルを開き、権限付与したい担当区分欄に1を入力してください
  </div>
  <div class="py-2 px-2 example-field">
    <div>
      設定例：Bチェーンの本部担当社にチェーンPOS参照権限を自動で付与する場合、該当チェーンのチェーン本部担当列に1を入力する
    </div>
    <img class="image" src="../../../assets/images/AB自動付与設定_チェーン担当者別付与.svg">
  </div>
  <div class="py-3 download-field">
    <div>
      チェーン担当者別設定ファイル：
    </div>
    <div style="margin-left: 0.5rem;">
      <button mat-raised-button color="primary" (click)="downloadPotionConfigFile()">ダウンロード</button>
    </div>
  </div>
  <div class="upload-filed">
    <div class="message-title">
      メッセージ
    </div>
    <div class="upload-control-filed">
      <app-message-field #messageFieldPos class="message-field"></app-message-field>
      <app-upload-file class="file-drop-field" (fileEvent)="selectedPotionConfig($event)"></app-upload-file>
      <div class="upload-button">
        <button mat-raised-button (click)="uploadPotionConfigFile()">
          <i class="fa fa-upload"> アップロード</i>
        </button>
      </div>
    </div>
  </div>
</div>
<!-- 組織別付与 -->
<div *ngIf="!isChain" class="contents-container">
  <div class="pb-3">
    設定した組織へ自動的に参照権限を付与します<br>
    ダウンロードファイルを開き、権限付与したい組織のコードを入力してアップロードしてください
  </div>
  <div class="py-2 px-2 example-field">
    <div>
      設定例：Aチェーンの権限を付与したい組織のコードを入力する
    </div>
    <img class="image" src="../../../assets/images/AB自動付与設定_組織別付与.svg">
  </div>
  <div class="py-3 download-field">
    <div>
      組織別参照権限自動付与設定のダウンロード：
    </div>
    <div style="margin-left: 0.5rem;">
      <button mat-raised-button color="primary" (click)="downloadOrganizationConfigFile()">ダウンロード</button>
    </div>
  </div>
  <div class="upload-filed">
    <div class="message-title">
      メッセージ
    </div>
    <div class="upload-control-filed">
      <app-message-field #messageFieldOrg class="message-field"></app-message-field>
      <app-upload-file class="file-drop-field" (fileEvent)="selectedOrganizationConfig($event)"></app-upload-file>
      <div class="upload-button">
        <button mat-raised-button (click)="uploadOrganizationConfigFile()">
          <i class="fa fa-upload"> アップロード</i>
        </button>
      </div>
    </div>
  </div>
</div>

<a #downloadLinkRef style="display: none">非表示リンク</a>

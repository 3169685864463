import { ChainMainChargeService } from './chain-main-charge.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { MaintenanceMenuService } from 'src/app/shared/maintenance-menu/maintenance-menu.service';
import { MessageFieldComponent } from 'src/app/shared/message-field/message-field.component';
import { UploadResultModel } from 'src/app/shared/message-field/message-field.viewmodel';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-chain-main-charge',
  templateUrl: './chain-main-charge.component.html',
  styleUrls: ['./chain-main-charge.component.scss']
})
export class ChainMainChargeComponent implements OnInit {
  @ViewChild('downloadLinkRef') public downloadLinkRef: ElementRef;
  /* メッセージフィールド */
  @ViewChild('messageField') private messageField: MessageFieldComponent;
  /* 店舗情報ファイル */
  private requestFile: NgxFileDropEntry;
  /* 拡張子 */
  public allowedExtension = '.xlsx';

  /**
   * コンストラクタ
   */
  constructor(
    public maintenanceMenuService: MaintenanceMenuService,
    public chainMainChargeService: ChainMainChargeService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {

  }
  /**
   * チェーン本部担当ファイルダウンロード
   * @param fileName ファイル名（ファイルパス）
   */
   public downloadRequestFile() {
    this.chainMainChargeService.downloadRequestFile('チェーン本部担当', this.downloadLinkRef);
  }

  /**
   * 申請ファイル取得
   */
  public setRequestFile(file: NgxFileDropEntry) {
    // エクセルファイル未選択
    if (file !== null && !file.relativePath.endsWith(this.allowedExtension)) {
      this.toastr.error('Excelファイル以外が選択されています。ファイルを再選択してください', 'Excelファイル非選択');
      return;
    }
    this.requestFile = file;
  }

  /**
   * 申請ファイルアップロード処理
   */
  public uploadRequestFile() {
    // ファイル未選択
    if (!this.requestFile || !this.requestFile.fileEntry.isFile) {
      this.toastr.error('店舗情報ファイルが選択されていません。', '店舗情報ファイル未選択');
      return;
    }
    const formData = new FormData();
    formData.append('organization', environment.organization);
    const fileEntry = this.requestFile.fileEntry as FileSystemFileEntry;
    fileEntry.file((file: File) => {
      formData.append('file', file, this.requestFile.relativePath);
      // 店舗情報ファイル取り込みAPI呼び出し
      this.chainMainChargeService.uploadRequest(formData).subscribe((res: UploadResultModel) => {
        this.messageField.pushMessage(res);
        // エラーあり
        if (res.errorCount > 0) {
          this.toastr.error('店舗情報のアップロードに失敗しました', 'アップロード失敗');
        }
        // 警告あり
        else if (res.warningCount > 0) {
          this.toastr.warning('店舗情報のアップロードで警告があります', 'アップロード警告');
        }
        // 正常終了
        else {
          this.toastr.info('店舗情報のアップロードが完了しました', 'アップロード完了');
        }
      });
    });
  }

  /**
   * ServiceNowの申請ページを開く
   */
  public applyOnServicenow() {
    window.open(environment.servicenow.masterMaintenanceURL, '_blank');
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { RouterService } from 'src/app/services/router/router.service';
import { MaintenanceMenuService } from 'src/app/shared/maintenance-menu/maintenance-menu.service';
import { MessageFieldComponent } from 'src/app/shared/message-field/message-field.component';
import { UploadResultModel } from 'src/app/shared/message-field/message-field.viewmodel';
import { ChainUpdatingService } from './update-chains.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-chains',
  templateUrl: './update-chains.component.html',
  styleUrls: ['./update-chains.component.scss']
})
export class UpdateChainsComponent implements OnInit {
  /* 表示コンテンツ */
  public viewContent: string = 'view/download';
  /* 拡張子 */
  public allowedExtension = ".xlsx"
  /* チェーン更新ファイル */
  private chainUpdatingFile: NgxFileDropEntry;
  /* メッセージフィールド */
  @ViewChild('messageField') private messageField: MessageFieldComponent;

  /**
   * コンストラクタ
   */
  constructor(public maintenanceMenuService: MaintenanceMenuService,
              private chainUpdatingService: ChainUpdatingService,
              private toastr: ToastrService,
              private routerService: RouterService) {}

  /**
   * 初期化処理
   */
  ngOnInit(): void {
    this.routerService.setPreviousPage();
  }

  /**
   * チェーン更新ファイル取得
   */
  public setUpdatingChainFile(file: NgxFileDropEntry) {
      // Excelファイル非選択
      if(file !== null && !file.relativePath.endsWith(this.allowedExtension)){
        this.toastr.error("Excelファイル以外が選択されています。ファイルを再選択してください", "Excelファイル非選択");
        return;
      };

      this.chainUpdatingFile = file;
   }

   /**
   * チェーン更新ファイルアップロード
   */
  public uploadUpdatingChainFile() {
    // ファイル未選択
    if (!this.chainUpdatingFile || !this.chainUpdatingFile.fileEntry.isFile) {
      this.toastr.error('チェーン更新ファイルが選択されていません。', 'チェーン更新ファイル未選択');
      return;
    }
    const formData = new FormData();
    formData.append('organization', environment.organization);
    const fileEntry = this.chainUpdatingFile.fileEntry as FileSystemFileEntry;
    fileEntry.file((file: File) => {
      formData.append('file', file, this.chainUpdatingFile.relativePath);

      // チェーン更新ファイル取り込みAPI呼び出し
      this.chainUpdatingService.exportChainUpdating(formData).subscribe((res: UploadResultModel) => {
        this.messageField.pushMessage(res);
        // エラーあり
        if(res.errorCount > 0){
          this.toastr.error('チェーン更新のアップロードに失敗しました', 'アップロード失敗');
        }
        // 警告あり
        else if(res.warningCount > 0){
          this.toastr.warning('チェーン更新のアップロードで警告があります', 'アップロード警告');
        }
        // 正常終了
        else{
          this.toastr.info('チェーン更新のアップロードが完了しました', 'アップロード完了');
        }
      })
    });
  }
}

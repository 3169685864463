import { MessageFieldComponent } from 'src/app/shared/message-field/message-field.component';
import { RegisterNewGoodsService } from './register-new-goods.service';
import { RouterService } from 'src/app/services/router/router.service';
import { Component, OnInit, AfterViewInit, AfterViewChecked, ViewChild, ElementRef } from '@angular/core';
import { MaintenanceMenuService } from 'src/app/shared/maintenance-menu/maintenance-menu.service';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { AppAuthService } from 'src/app/services/auth/app-auth.service';
import { environment } from 'src/environments/environment';
import { ScopeViewModel } from 'src/app/services/authority/authority.service.viewmodel';
import { AuthorityService } from 'src/app/services/authority/authority.service';

@Component({
  selector: 'app-register-new-goods',
  templateUrl: './register-new-goods.component.html',
  styleUrls: ['./register-new-goods.component.scss'],
})
export class RegisterNewGoodsComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @ViewChild(MessageFieldComponent, { static: false }) messageFieldComponent: MessageFieldComponent;
  @ViewChild('downloadLinkRef') public downloadLinkRef: ElementRef;
  private FileData: NgxFileDropEntry;
  private allowedExtension = '.xlsx';
  public tableauUrl = environment.tableView.newGoods;
  /** true:ダウンロード中 */
  public downloading = false;
  public organization = environment.organization;
    /** 権限スコープ */
    public scopes: ScopeViewModel;

  /**
   * コンストラクタ
   */
  constructor(
    public maintenanceMenuService: MaintenanceMenuService,
    private registerNewGoodsService: RegisterNewGoodsService,
    private routerService: RouterService,
    private toastr: ToastrService,
    private appAuthService: AppAuthService,
    private authorityService: AuthorityService,
  ) { }

  ngOnInit(): void {
    this.getScopes();
    this.routerService.setPreviousPage();
  }
  ngAfterViewInit(): void {
  }

  ngAfterViewChecked(): void {
  }

  private async getScopes() {
    this.scopes = await this.authorityService.scopes();
  }

  public setFile(file: NgxFileDropEntry) {
    // Excelファイル未選択
    if (file !== null && !file.relativePath.endsWith(this.allowedExtension)) {
      this.toastr.error('Excelファイル以外が選択されています。ファイルを再選択してください", "Excelファイル非選択');
      return;
    }
    this.FileData = file;
  }

  public uploadFile() {
    const formData = new FormData();
    // ファイル未選択
    if (!this.FileData || !this.FileData.fileEntry.isFile) {
      this.toastr.error('新商品マスタファイルが選択されていません。', '新商品マスタファイル未選択');
      return;
    }
    formData.append('organization', environment.organization);
    const fileEntry = this.FileData.fileEntry as FileSystemFileEntry;
    fileEntry.file((file: File) => {
      formData.append('file', file, this.FileData.relativePath);

      this.registerNewGoodsService.uploadNewGoodsList(formData).subscribe(res => {
        this.messageFieldComponent.pushMessage(res);
        if (res.fileName) {
          this.downloadConvertedFile(res.fileName);
        }

        // エラーあり
        if (res.errorCount > 0) {
          this.toastr.error(
            '新商品マスタファイルのアップロードに失敗しました', 'アップロード失敗'
          );
        }
        // 警告あり
        else if (res.warningCount > 0) {
          this.toastr.warning(
            '新商品マスタファイルのアップロードで警告があります', 'アップロード警告'
          );
        }
        // 正常終了
        else {
          this.toastr.info('新商品マスタファイルのアップロードが完了しました', 'アップロード完了');
        }
      });
    });
  }

  /**
   * 指定された商品マスタ変換ファイルをダウンロードします
   * @param fileName ファイル名（ファイルパス）
   */
  private downloadConvertedFile(fileName: string) {
    this.registerNewGoodsService.downloadGoodsList(fileName, '新商品リスト_変換ファイル', this.downloadLinkRef);
  }

  public clickedDownLoad() {
    this.registerNewGoodsService.downloadIntageGoodsList(environment.organization, '新商品リスト', this.downloadLinkRef);
  }
}

<div class="d-flex flex-column align-items-center">
  <div class="text-left">
    OKボタンをクリックしてください。<br>
    認証確認の為、ポップアップウインドウが表示されますが、正常な動作です。
  </div>
  <div class="mt-4">
    <button mat-raised-button color="primary" (click)="clickedOK()">
      OK
    </button>
  </div>
</div>


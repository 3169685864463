<div class="main px-3 py-3">
  <div class="page-title-bg">
  </div>
  <div class="page-title">
    <div class="title-container">店舗マスタダウンロード・新規変更申請</div>
  </div>
  <mat-tab-group class="page-body mat-tab-fill-height" animationDuration="0ms">
    <!-- 共通店舗マスタのみ -->
    <mat-tab label="ダウンロード／申請" class="label" *ngIf="scopes?.showTabDownloadCommonStoreMaster">
      <app-download-store-master mode="common"></app-download-store-master>
    </mat-tab>
    <!-- チェーン固有を含む -->
    <mat-tab label="ダウンロード／申請" class="label" *ngIf="scopes?.showTabDownloadChainStoreMaster">
      <app-download-store-master mode="chain"></app-download-store-master>
    </mat-tab>

    <!-- アップロード -->
    <mat-tab label="店舗マスタ アップロード" *ngIf="scopes?.showTabUploadStoreMaster" class="label">
      <app-upload-store-master></app-upload-store-master>
    </mat-tab>
  </mat-tab-group>
</div>

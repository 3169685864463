<div class="main px-3 py-3">
  <div class="page-title-bg">
  </div>
  <div class="page-title">
    <div class="title-container">チェーン追加改修申請</div>
  </div>
  <mat-tab-group class="page-body mat-tab-fill-height" animationDuration="0ms">

    <mat-tab label="ダウンロード／申請" class="label">
      <div class="tab-body">
        <div class="heading">
          チェーン追加改修申請書をダウンロードする
        </div>
        <div class="ps-4 mt-3">
          <button mat-raised-button color="primary" (click)="downloadRequestFile()">
            <i class="fa fa-download"></i>
            ダウンロード
          </button>
        </div>
        <div class="heading">チェーン追加改修を申請する</div>
        <div class="ps-4 mt-3">
          <button mat-raised-button color="accent" class="apply-button" (click)="applyOnServicenow()">
            アップロード申請
          </button>
          <button mat-raised-button color="primary" class="ms-4" (click)="applyCheck()">
            申請状況確認
          </button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<!-- 非表示ダウンロードリンク -->
<a #downloadLinkRef style="display: none">非表示リンク</a>

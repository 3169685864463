import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { AuthorityService } from 'src/app/services/authority/authority.service';
import { ScopeViewModel } from 'src/app/services/authority/authority.service.viewmodel';
import { MaintenanceMenuService } from 'src/app/shared/maintenance-menu/maintenance-menu.service';
import { TableauEmbedComponent } from 'src/app/shared/tableau-embed/tableau-embed.component';
import { environment } from 'src/environments/environment';
import { StoreMasterDownloadQuery } from '../update-stores.model';
import { UpdateStoresService } from '../update-stores.service';

type ChainSelectionTypes = 'charge_all' | 'charge_chain' | 'all' | 'chain';
/** チェーン選択の一覧 */
class ChainSelectionItem {
  public name: string;
  public value: ChainSelectionTypes;
  public charge: boolean;
  public selectable: boolean;
}

@Component({
  selector: 'app-download-store-master',
  templateUrl: './download-store-master.component.html',
  styleUrls: ['./download-store-master.component.scss']
})
export class DownloadStoreMasterComponent implements OnInit {
  /** ダウンロードリンク */
  @ViewChild('downloadLinkRef') public downloadLinkRef: ElementRef;
  
  /** ダウンロードモード: common or chain, default: common */
  @Input()
  public mode: string;

  /* フィルタリングされたチェーンリスト */
  public filteredChainList: Observable<any>;
  /* 表示対象のチェーンリスト */
  private chainList = [];
  /* 担当チェーンリスト */
  private chainListByUser = [];
  /* 全チェーン */
  private allChainList = [];
  /* チェーン選択オートコンプリートフォーム */
  public chainListControl = new FormControl();
  /** 店舗マスタビューのディレクトリ */
  public tableauStoreMasterDir = '';

  /** チェーン選択タイプ */
  public chainSelectionTypes: ChainSelectionItem[] = [
    { name: '全ての担当チェーンを表示', value: 'charge_all', charge: true, selectable: false },
    { name: '担当チェーンを選択', value: 'charge_chain', charge: true, selectable: true },
    { name: '全てのチェーンを表示', value: 'all', charge: false, selectable: false },
    { name: 'チェーンを選択', value: 'chain', charge: false, selectable: true },
  ];
  public frmChainSelectionType: FormControl = new FormControl('');
  /** チェーンの選択可否 true: チェーン選択を行う必要がある */
  public chainSelectable = true;

  /** 権限スコープ */
  public scopes: ScopeViewModel;

  constructor(
    public maintenanceMenuService: MaintenanceMenuService,
    private updateStoresService: UpdateStoresService,
    private authorityService: AuthorityService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.initialize();
  }

  /** 初期化 */
  private async initialize() {
    this.scopes = await this.authorityService.scopes();

    this.updateStoresService.initialize(environment.organization).subscribe(res => {
      // チェーンリスト取得
      this.chainList = res.chainList;
      this.allChainList = res.chainList;
      this.chainListByUser = this.authorityService.chainListByUser;

      // チェーンリストサジェスチョン設定
      this.filteredChainList = this.chainListControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this.autoCompleteFilter(value)
        )
      );

      if (this.scopes.showChainCharge) {
        this.frmChainSelectionType.setValue('charge_chain');
      } else {
        this.chainSelectionTypes = this.chainSelectionTypes.filter(x => x.charge === false);
        this.frmChainSelectionType.setValue('chain');
      }

      // URLからパラメータ取得
      this.route.paramMap.subscribe((p: ParamMap) => {

        // チェーンコード
        const chain = this.allChainList.find(x => x.chainCd === p.get('chainCd'));
        if (!chain) {
          return;
        }

        if (this.chainListByUser.find(x => x.chainCd === chain.chainCd) && this.scopes.showChainCharge) {
          // 担当チェーンにパラメータのチェーンが居る
          this.frmChainSelectionType.setValue('charge_chain');
          this.chainListControl.setValue(chain.chainCd);
        } else {
          // 無ければ全チェーンで選択する
          this.frmChainSelectionType.setValue('chain');
          this.chainListControl.setValue(chain.chainCd);
        }
      });
    });

    // チェーン選択種類変更イベント
    this.frmChainSelectionType.valueChanges.subscribe(x => {
      const item = this.chainSelectionTypes.find(y => y.value === x);
      if (!item) {
        return;
      }

      this.chainSelectable = item.selectable;
      if (item.charge === true) {
        // 担当チェーンが選択された場合
        this.chainList = this.authorityService.chainListByUser;
        this.filteredChainList = this.chainListControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this.autoCompleteFilter(value))
          );
      } else {
        // 全チェーンが選択された場合
        this.chainList = this.allChainList;
        this.filteredChainList = this.chainListControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this.autoCompleteFilter(value))
          );
      }

      // チェーン選択リセット
      this.chainListControl.setValue(null);
    });
  }


  /**
   * オートコンプリート表示処理
   */
  public autoCompleteDisplay(value: any) {
    if (!value) {
      return '';
    }
    const chain = this.chainList.find(x => x.chainCd === value);
    return `${chain.chainCd} ${chain.chainName}`;
  }

  /** 
   * チェーン選択欄のリセット
   * @param e: クリックイベント
   */
  public clearChainSelection(e: Event) {
    this.chainListControl.reset();
    e.stopPropagation();
  }

  /**
   * オートコンプリートフィルタリング
   * @param filteringStr フィルタリングする文字列
   */
  private autoCompleteFilter(filteringStr: any) {
    if (filteringStr) {
      const filteredKeys = filteringStr.toLowerCase();
      const filtered = this.chainList.filter(it => it.chainName.toLowerCase().includes(filteredKeys) || it.chainCd.includes(filteredKeys));
      return filtered;
    } else {
      return this.chainList;
    }
  }

  /** 店舗マスタのダウンロードボタンクリックイベント */
  public async clickedStoreMasterDownload() {
    // ダウンロードモデル
    let fileName = '店舗マスタ_全チェーン共通';
    const model: StoreMasterDownloadQuery = {
      userId: await this.authorityService.getUserId(),
      organization: environment.organization,
      mode: this.mode,
      chainCdList: [],
    };

    // チェーン固有の場合
    if (this.chainSelectable) {
      const chain = this.chainList.find(x => x.chainCd === this.chainListControl.value);
      if (!chain) {
        this.toastr.error('チェーンを選択してください', 'チェーン非選択');
        return;
      }
      model.chainCdList.push(chain.chainCd);
      fileName = `店舗マスタ_${chain.chainName}`;

      // 全ての担当チェーンの場合
    } else if (this.frmChainSelectionType.value === 'charge_all') {
      this.chainList.forEach(x => {
        model.chainCdList.push(x.chainCd);
      });
      fileName = '店舗マスタ_全担当チェーン';
    }

    // ダウンロード開始
    this.updateStoresService.downloadStoreMaster(model, fileName, this.downloadLinkRef);
  }

  /** 申請ファイルのダウンロード */
  public async downloadApplyFile() {
    const model = {
      organization: environment.organization,
      chainCdList: []
    };

    let fileName ='店舗マスタ追加更新申請書_全チェーン共通';
    if (this.chainSelectable && this.mode === 'chain') {
      // 単一チェーン選択の場合
      const chain = this.chainList.find(x => x.chainCd === this.chainListControl.value);
      if (!chain) {
        this.toastr.error('チェーンを選択してください', 'チェーン非選択');
        return;
      }
      model.chainCdList.push(chain.chainCd);
      fileName = `店舗マスタ追加更新申請書_${chain.chainName}`;
    }

    this.updateStoresService.downloadApplyFile(model, fileName, this.downloadLinkRef);
  }

  /**
   * ServiceNowの申請ページを開く
   */
  public applyOnServicenow() {
    window.open(environment.servicenow.storeMasterMaintenanceURL, '_blank');
  }

  /**
   * * ServiceNoの申請履歴ページを開く
   */
  public openApplyList() {
    window.open(environment.servicenow.myRequestsURL, '_blank');
  }

}

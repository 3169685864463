import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LogModel } from './log.model';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LogService {
  /**
   * コンストラクタ
   */
  constructor(private http: HttpClient) { }

  /**
   * ログ出力API呼び出し
   * @param log ログ出力内容
   */
  public writeLog(log: LogModel) {
    if (environment.cloudLogging === true) {
      this.http.post<any>(environment.apiEndPoint + 'api/log', log).subscribe();
    }
  }
}

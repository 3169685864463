import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { ToastrModule } from 'ngx-toastr';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatExpansionModule} from '@angular/material/expansion';

import { environment } from 'src/environments/environment';
import { LoginComponent } from './pages/login/login.component';
import { HomeComponent } from './pages/home/home.component';
import { TestComponent } from './pages/test/test.component';
import { BlockUIModule } from 'ng-block-ui';
import { ErrorComponent } from './pages/error/error/error.component';
import { NotFoundComponent } from './pages/error/not-found/not-found.component';
import { MaintenanceMenuComponent } from './shared/maintenance-menu/maintenance-menu.component';
import { RegisterNewGoodsComponent } from './pages/register-new-goods/register-new-goods.component';
import { SolveUnmatchedGoodsComponent } from './pages/solve-unmatched-goods/solve-unmatched-goods.component';
import { RegisterGoodsComponent } from './pages/register-goods/register-goods.component';
import { UpdateGoodsComponent } from './pages/update-goods/update-goods.component';
import { ConvertGoodsAttributeComponent } from './pages/convert-goods-attribute/convert-goods-attribute.component';
import { ConfigureChainCalendarComponent } from './pages/configure-chain-calendar/configure-chain-calendar.component';
import { UpdateCalendarComponent } from './pages/update-calendar/update-calendar.component';
import { SetReferenceControlsComponent } from './pages/set-reference-controls/set-reference-controls.component';
import { SetMaintenanceControlsComponent } from './pages/set-maintenance-controls/set-maintenance-controls.component';
import { MessageFieldComponent } from './shared/message-field/message-field.component';
import { UploadFileComponent } from './shared/upload-file/upload-file.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { DownloadButtonComponent } from './shared/download-button/download-button.component';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatStepperModule } from '@angular/material/stepper';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { TableauEmbedComponent } from './shared/tableau-embed/tableau-embed.component';
import { ApiAuth0Interceptor } from './services/interceptor/api.auth0.interceptor';
import { UpdateChainsComponent } from './pages/update-chains/update-chains.component';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { AutoAuthorizationSettingAbComponent } from './pages/set-reference-controls/auto-authorization-setting-ab/auto-authorization-setting-ab.component';
import { AutoAuthorizationSettingAsdComponent } from './pages/set-reference-controls/auto-authorization-setting-asd/auto-authorization-setting-asd.component';
import { DirectAuthorizationSettingAbComponent } from './pages/set-reference-controls/direct-authorization-setting-ab/direct-authorization-setting-ab.component';
import { MainContainerComponent } from './pages/main-container/main-container.component';
import { MaintenanceComponent } from './pages/error/maintenance/maintenance.component';
import { CustomErrorHandler } from './services/error-handler/custom-error-handler';
import { AuthConfirmDialogComponent } from './services/auth/auth-confirm-dialog/auth-confirm-dialog.component';
import { RootComponent } from './pages/root/root.component';
import { AuthoritySelectorComponent } from './pages/authority-selector/authority-selector.component';
import { DownloadStoreMasterComponent } from './pages/store-master/download-store-master/download-store-master.component';
import { UploadStoreMasterComponent } from './pages/store-master/upload-store-master/upload-store-master.component';
import { RequestForChainComponent } from './pages/request-for-chain/request-for-chain.component';
import { ChainMainChargeComponent } from './pages/chain-main-charge/chain-main-charge.component';
import { IndividualAuthorityComponent } from './pages/individual-authority/individual-authority.component';
import { StoreMasterComponent } from './pages/store-master/store-master.component';
import { StoreUnmatchedComponent } from './pages/store-unmatched/store-unmatched.component';
import { UploadUnmatchedStoreComponent } from './pages/store-unmatched/upload-unmatched-store/upload-unmatched-store.component';
import { DownloadUnmatchedStoreComponent } from './pages/store-unmatched/download-unmatched-store/download-unmatched-store.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    TestComponent,
    ErrorComponent,
    NotFoundComponent,
    MaintenanceMenuComponent,
    RegisterNewGoodsComponent,
    SolveUnmatchedGoodsComponent,
    RegisterGoodsComponent,
    UpdateGoodsComponent,
    ConvertGoodsAttributeComponent,
    ConfigureChainCalendarComponent,
    UpdateCalendarComponent,
    SetReferenceControlsComponent,
    SetMaintenanceControlsComponent,
    MessageFieldComponent,
    UploadFileComponent,
    DownloadButtonComponent,
    TableauEmbedComponent,
    UpdateChainsComponent,
    AutoAuthorizationSettingAbComponent,
    AutoAuthorizationSettingAsdComponent,
    DirectAuthorizationSettingAbComponent,
    MainContainerComponent,
    MaintenanceComponent,
    AuthConfirmDialogComponent,
    RootComponent,
    AuthoritySelectorComponent,
    DownloadStoreMasterComponent,
    UploadStoreMasterComponent,
    RequestForChainComponent,
    ChainMainChargeComponent,
    IndividualAuthorityComponent,
    StoreMasterComponent,
    StoreUnmatchedComponent,
    UploadStoreMasterComponent,
    DownloadUnmatchedStoreComponent,
    UploadUnmatchedStoreComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    BlockUIModule.forRoot(),
    MatButtonModule,
    MatListModule,
    MatDividerModule,
    NgxFileDropModule,
    ToastrModule.forRoot(),
    MatSelectModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatStepperModule,
    MatRadioModule,
    MatIconModule,
    MatTabsModule,
    MatCheckboxModule,
    MatExpansionModule,
    AuthModule.forRoot(environment.auth0)
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiAuth0Interceptor,
      multi: true
    },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: AuthHttpInterceptor,
    //   multi: true
    // },
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

import { Component, OnInit } from '@angular/core';
import { RouterService } from 'src/app/services/router/router.service';

@Component({
  selector: 'app-update-goods',
  templateUrl: './update-goods.component.html',
  styleUrls: ['./update-goods.component.scss']
})
export class UpdateGoodsComponent implements OnInit {

  constructor(
    private routerService: RouterService
  ) { }

  ngOnInit(): void {
    this.routerService.setPreviousPage();
  }

}

import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { MaintenanceMenuService } from 'src/app/shared/maintenance-menu/maintenance-menu.service';
import { ToastrService } from 'ngx-toastr';
import { CalendarUpdatingService } from 'src/app/pages/update-calendar/calendar-updating.service';
import { RouterService } from 'src/app/services/router/router.service';
import { MessageFieldComponent } from 'src/app/shared/message-field/message-field.component';
import { UploadResultModel } from 'src/app/shared/message-field/message-field.viewmodel';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { AppAuthService } from 'src/app/services/auth/app-auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-update-calendar',
  templateUrl: './update-calendar.component.html',
  styleUrls: ['./update-calendar.component.scss']
})
export class UpdateCalendarComponent implements OnInit, AfterViewInit {
  /* カレンダー更新ファイル */ 
  private calendarUpdatingFile: NgxFileDropEntry;
  /* 表示コンテンツ */ 
  public viewContent: string = 'view/download';
  /* メッセージフィールド */ 
  @ViewChild('messageField') private messageField: MessageFieldComponent;
  /* 拡張子 */ 
  public allowedExtension = ".xlsx"

  /**
   * コンストラクタ
   */
  constructor(public maintenanceMenuService: MaintenanceMenuService,
              private toastr: ToastrService,
              private calendarUpdatingService: CalendarUpdatingService,
              private routerService: RouterService,
              private appAuthService: AppAuthService) {}

  /**
   * 初期化処理
   */
  ngOnInit(): void {
    this.routerService.setPreviousPage();
  }

  /**
   * UI描画後処理
   */
  ngAfterViewInit(): void {
  }

  /**
   * カレンダ更新ファイル取得
   */
  public setCalendarUpdatingFile(file: NgxFileDropEntry) {
    // Excelファイル非選択
    if(file !== null && !file.relativePath.endsWith(this.allowedExtension)){
      this.toastr.error("Excelファイル以外が選択されています。ファイルを再選択してください", "Excelファイル非選択");
      return;
    };

    this.calendarUpdatingFile = file;
  }

  /**
   * カレンダ更新ファイルアップロード
   */
  public uploadCalendarUpdatingFile() {
    // ファイル未選択
    if (!this.calendarUpdatingFile || !this.calendarUpdatingFile.fileEntry.isFile) {
      this.toastr.error('カレンダ更新ファイルが選択されていません。', 'カレンダ更新ファイル未選択');
      return;
    }

    const formData = new FormData();
    formData.append('organization', environment.organization);
    const fileEntry = this.calendarUpdatingFile.fileEntry as FileSystemFileEntry;
    fileEntry.file((file: File) => {
      formData.append('file', file, this.calendarUpdatingFile.relativePath);

      // カレンダ更新ファイル取り込みAPI呼び出し
      this.calendarUpdatingService.exportCalendarUpdating(formData).subscribe((res: UploadResultModel) => {
        this.messageField.pushMessage(res);
        // エラーあり
        if(res.errorCount > 0){
          this.toastr.error('カレンダ更新のアップロードに失敗しました', 'アップロード失敗');
        }
        // 警告あり
        else if(res.warningCount > 0){
          this.toastr.warning('カレンダ更新のアップロードで警告があります', 'アップロード警告');
        }
        // 正常終了
        else{
          this.toastr.info('カレンダ更新のアップロードが完了しました', 'アップロード完了');
        }
      })
    });
  }
}

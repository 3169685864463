<div class="main px-3 py-3">
  <div class="page-title-bg">
  </div>
  <div class="page-title">
    <div class="title-container">商品アンマッチダウンロード・申請</div>
  </div>
  <mat-tab-group class="page-body mat-tab-fill-height" animationDuration="0ms">
    <mat-tab label="ダウンロード/申請" class="label">
      <div class="tab-body">
        <div class="heading">チェーンを選択する</div>
        <div class="select-chain-container ps-4 mt-3">
          <!-- チェーン選択種類 -->
          <mat-form-field appearance="fill" class="select-chain-type">
            <mat-label>ダウンロードするリストの選択</mat-label>
            <mat-select [formControl]="frmChainSelectionTypeForDownload">
              <mat-option *ngFor="let item of chainSelectionTypes" [value]="item.value">
                {{item.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- チェーン選択 -->
          <mat-form-field floatLabel="never" class="select-chain-field ms-2"
            [ngClass]="{ 'prevent-touch': chainSelectableForDownload !== true }" appearance="fill">
            <mat-label>チェーンの選択</mat-label>
            <input type="text" placeholder="選択してください" matInput [formControl]="chainListControlForDownload"
              [matAutocomplete]="downloadAutoComplete">
            <mat-autocomplete #downloadAutoComplete [displayWith]="autoCompleteDisplayForDownload.bind(this)">
              <mat-option *ngFor="let option of filteredChainListForDownload | async" [value]="option.chainCd">
                {{option.chainCd}} {{ option.chainName }}
              </mat-option>
            </mat-autocomplete>
            <button *ngIf="chainListControlForDownload.value" matSuffix mat-icon-button aria-label="Clear"
              (click)="clearChainSelectionForDownload($event)">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="heading">商品アンマッチリストをダウンロードする</div>
        <div class="ps-4 mt-3">
          <button mat-raised-button color="primary" (click)="clickedDownLoad()">
            <i class="fa fa-download"></i>
            ダウンロード
          </button>
        </div>
        <div *ngIf="scopes?.showButtonApplyUnmatchedGoods || scopes?.showButtonCheckApplyUnmatchedGoods"
          class="heading">商品のアンマッチ登録を申請する</div>
        <div class="ps-4 mt-3">
          <button *ngIf="scopes?.showButtonApplyUnmatchedGoods" mat-raised-button color="accent" class="apply-button"
            (click)="applyOnServicenow()">
            アップロード申請
          </button>
          <button *ngIf="scopes?.showButtonCheckApplyUnmatchedGoods" mat-raised-button color="primary" class="ms-4"
            (click)="openApplyList()">
            申請状況確認
          </button>
        </div>
      </div>
    </mat-tab>

    <mat-tab *ngIf="scopes?.showTabUploadUnmatchedGoods" label="アップロード" class="label">
      <!-- チェーン選択欄 -->
      <div class="select-chain-container mt-4">
        <mat-checkbox color="primary" [(ngModel)]="chainSelectableForUpload">
          POSチェーン独自属性を含める
        </mat-checkbox>
        <mat-form-field [ngClass]="{ 'prevent-touch': !chainSelectableForUpload }" floatLabel="never" appearance="fill"
          style="width: 40%; margin-left: 1rem;">
          <input type="text" placeholder="00000000 チェーン" matInput [formControl]="chainListControlForUpload"
            [matAutocomplete]="uploadAutoComplete">
          <mat-autocomplete #uploadAutoComplete [displayWith]="autoCompleteDisplayForUpload.bind(this)">
            <mat-option *ngFor="let option of filteredChainListForUpload | async" [value]="option.chainCd">
              {{ option.chainCd }} {{ option.chainName }}
            </mat-option>
          </mat-autocomplete>
          <button *ngIf="chainListControlForUpload.value" matSuffix mat-icon-button aria-label="Clear"
            (click)="clearChainSelectionForUpload($event)">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="expository-text">
        ※アップロードするファイルにPOSチェーン独自属性が含まれる場合、チェックを入れて該当のチェーンを選択してください
      </div>
      <!-- アップロード -->
      <div class="upload-field">
        <div class="message-title">
          メッセージ
        </div>
        <div class="upload-control-filed">
          <app-message-field class="message-field"></app-message-field>
          <app-upload-file class="file-drop-field" (fileEvent)="setFile($event)"></app-upload-file>
        </div>
        <div class="upload-button">
          <button mat-raised-button (click)="uploadFile()">
            <i class="fa fa-upload"> アップロード</i>
          </button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<!-- 非表示ダウンロードリンク -->
<a #downloadLinkRef style="display: none">非表示リンク</a>

import { Params } from '@angular/router';
import { ElementRef, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { FileDownloadService } from 'src/app/services/file-download/file-download.service';

@Injectable({
  providedIn: 'root'
})
export class AutoAuthorizationSettingService {
  /**
   * コンストラクタ
   */
  constructor(
    private http: HttpClient,
    private dl: FileDownloadService) { }

  /**
   * 組織別参照権限自動付与ファイルアップロード
   * @param data アップロード情報
   */
  public uploadOrganizationConfig<T>(data: FormData): Observable<T> {
    return this.http.post<T>(environment.apiEndPoint + 'api/set-reference-control/organization', data);
  }

  /**
   * 自動付与設定結果ファイルのダウンロード
   * @param path ダウンロードパス
   * @returns ファイルBlob
   */
  public downloadAuthorityConfigResult(path: string , dlname: string, el: ElementRef) {
    this.dl.get('api/set-reference-control/config/result', {
      'organization': environment.organization,
      'path': path,
    }, dlname, el);
  }

  /**
   * 組織一括設定ファイルダウンロード
   * @returns 組織一括設定ファイル
   */
  public downloadConfigFile(dlname: string, el: ElementRef) {
    this.dl.get('api/set-reference-control/organization', {
      'organization': environment.organization
    }, dlname, el);
  }

  /**
   * 担当一括設定ファイルダウンロード
   * @returns 担当一括設定ファイル
   */
  public downloadPotionFile(userId: string, dlname: string, el: ElementRef) {
    this.dl.get('api/set-reference-control/position', {
      'organization': environment.organization,
      'userId': userId
    }, dlname, el);
  }

  /**
   * 担当別参照権限自動付与ファイルアップロード
   * @param data アップロード情報
   */
  public uploadPositionConfig<T>(data: FormData): Observable<T> {
    return this.http.post<T>(environment.apiEndPoint + 'api/set-reference-control/position', data);
  }

  /**
   * 手動設定ファイルのダウンロード
   * @returns 手動設定ファイル
   */
  public downloadDirectConfigFile(dlname: string, el: ElementRef) {
    this.dl.get('api/set-reference-control/user/direct', {
      'organization': environment.organization,
    }, dlname, el);
  }
}

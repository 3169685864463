import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { environment } from 'src/environments/environment';

declare var tableau: any;

@Component({
  selector: 'app-tableau-embed',
  templateUrl: './tableau-embed.component.html',
  styleUrls: ['./tableau-embed.component.scss']
})
export class TableauEmbedComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy{
  /** 画面エレメントのID */
  @Input() public elementId: string;
  /** 表示するviewのURLのディレクトリ */
  @Input() url: string;

  /** ビューコントローラ */
  private viz: any;

  /** ビューのロード完了 */
  private loaded: boolean = false;

  /**
   * コンストラクタ
   */
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this.url) {
      this.initViz(environment.tableauHost + this.url);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.url) {
      if (changes.url.previousValue !== changes.url.currentValue && changes.url.currentValue) {
        const u = environment.tableauHost + changes.url.currentValue;
        this.initViz(u);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.viz) {
      this.viz.dispose();
    }
    this.viz = null;
  }

  /**
   * Tableau表示
   * @param url 表示するビューのURL
   */
  private initViz(url: string): void {
    // すでに実体化していれば削除
    if (this.viz) {
      this.viz.dispose();
    }
    this.viz = null;
    this.loaded = false;

    // Tableau実体化
    this.viz = new tableau.Viz(document.getElementById(this.elementId), url, {
      width: '100%',
      height: '100%',
      hideToolbar: true
    });
  }

  /** vizが作成待ち */
  private waitCreatedViz(): Promise<any> {
    const vizTimer = (resolve: (value: any) => void) => {
      if (this.viz) {
        resolve(null);
      }

      setTimeout(() => {
        vizTimer(resolve);
      }, 100);
    };

    return new Promise<any>((resolve, reject) => {
      vizTimer(resolve);
    });
  }

  /** VIZのロード完了待ち */
  private waitCompletedLoad(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      if (this.loaded === true) {
        resolve(null);
      } else {
        // ロード完了イベント
        this.viz.addEventListener(tableau.TableauEventName.CUSTOM_VIEW_LOAD, () => {
          this.loaded = true;
          resolve(null);
        });
      }
    });
  }


  /**
   * 対象フィールドをフィルタリングします
   * @param field フィールド名
   * @param values フィルター値
   */
  public async replaceFilter(field: string, values: string[]) {
    await this.waitCreatedViz();
    await this.waitCompletedLoad();

    const sheet = this.viz?.getWorkbook()?.getActiveSheet();
    if (sheet) {
      await sheet.applyFilterAsync(field, values, tableau.FilterUpdateType.REPLACE);
    }
  }

  /**
   * 対象フィールドのフィルタをクリアします
   * @param field フィールド名
   * @param values フィルター値
   */
  public async clearFilter(field: string) {
    await this.waitCreatedViz();
    await this.waitCompletedLoad();

    const sheet = this.viz?.getWorkbook()?.getActiveSheet();
    if (sheet) {
      await sheet.applyFilterAsync(field, '', tableau.FilterUpdateType.ALL);
    }
  }

  /**
   * Excelダウンロード
   */
  public downloadExcel(): void {
    this.viz.exportCrossTabToExcel();
  }
}

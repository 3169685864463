import { Component, OnInit, ViewChild } from '@angular/core';
import { MaintenanceMenuService } from 'src/app/shared/maintenance-menu/maintenance-menu.service';
import { ToastrService } from 'ngx-toastr';
import { CalendarSettingService } from 'src/app/pages/configure-chain-calendar/calendar-setting.service';
import { RouterService } from 'src/app/services/router/router.service';
import { UploadResultModel } from 'src/app/shared/message-field/message-field.viewmodel';
import { MessageFieldComponent } from 'src/app/shared/message-field/message-field.component';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { AppAuthService } from 'src/app/services/auth/app-auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-configure-chain-calendar',
  templateUrl: './configure-chain-calendar.component.html',
  styleUrls: ['./configure-chain-calendar.component.scss']
})
export class ConfigureChainCalendarComponent implements OnInit {
  /* カレンダー設定ファイル */
  private calendarSettingFile: NgxFileDropEntry;
  /* 表示コンテンツ */
  public viewContent: string = 'view/download';
  /* メッセージフィールド */
  @ViewChild('messageField') messageField: MessageFieldComponent;
  /* 拡張子 */
  public allowedExtension = ".xlsx"

  /**
   * コンストラクタ
   */
  constructor(
    public maintenanceMenuService: MaintenanceMenuService,
    private toastr: ToastrService,
    private calendarSettingService: CalendarSettingService,
    private routerService: RouterService) { }

  /**
   * 初期化処理
   */
  ngOnInit(): void {
    this.routerService.setPreviousPage();
  }

  /**
   * カレンダ設定ファイル取得
   */
  public setCalendarSettingFile(file: NgxFileDropEntry) {
    // エクセルファイル未選択
    if(file !== null && !file.relativePath.endsWith(this.allowedExtension)){
      this.toastr.error("Excelファイル以外が選択されています。ファイルを再選択してください", "Excelファイル非選択");
      return;
    };
    this.calendarSettingFile = file;
  }

  /**
   * カレンダ設定ファイルアップロード
   */
  public uploadCalendarSettingFile() {
    // ファイル未選択
    if (!this.calendarSettingFile || !this.calendarSettingFile.fileEntry.isFile){
      this.toastr.error('カレンダ設定ファイルが選択されていません。', 'カレンダ設定ファイル未選択');
      return;
    }

    const formData = new FormData();
    formData.append('organization', environment.organization);
    const fileEntry = this.calendarSettingFile.fileEntry as FileSystemFileEntry;
    fileEntry.file((file: File) => {
      formData.append('file', file, this.calendarSettingFile.relativePath);
      // カレンダ設定ファイル取り込みAPI呼び出し
      this.calendarSettingService.exportCalendarSetting(formData).subscribe((res:UploadResultModel) => {
        this.messageField.pushMessage(res);
        // エラーあり
        if(res.errorCount > 0){
          this.toastr.error('カレンダ設定のアップロードに失敗しました', 'アップロード失敗');
        }
        // 警告あり
        else if(res.warningCount > 0){
          this.toastr.warning('カレンダ設定のアップロードで警告があります', 'アップロード警告');
        }
        // 正常終了
        else{
          this.toastr.info('カレンダ設定のアップロードが完了しました', 'アップロード完了');
        }
      })
    });
  }
}

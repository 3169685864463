import { Injectable, EventEmitter } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { Observable, Subscriber } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RouterService {

  private PREVIOUS_PAGE: EventEmitter<string> = new EventEmitter<string>();
  public get previousPage(): EventEmitter<string> {
    return this.PREVIOUS_PAGE;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router) { }

  /**
   * 指定したパスへ移動します
   * @param url URL
   */
  public navigateByUrl(url: string): Promise<boolean> {
    return this.router.navigateByUrl(url);
  }

  public setPreviousPage() {
    const url = this.router.url;
    if (!url || url === null) {
      return;
    }

    let path = url.slice(1);
    path = path.split('?')[0];
    setTimeout(() => {
      this.PREVIOUS_PAGE.emit(path);
    }, 0);
  }

  /**
   * URLからクエリパラメータを取得します
   * @returns
   */
  public getQueryParams(): Observable<Params> {
    return this.activatedRoute.queryParams;
  }
}

<div class="d-flex flex-column main px-3 py-3" [ngClass]="{ 'pt-5': !maintenanceMenuService.visibleMenu }">
  <div class="page-title-bg">
  </div>
  <div class="page-title">
    <div class="title-container">変換表ダウンロード・申請</div>
  </div>

  <mat-tab-group class="page-body mat-tab-fill-height" animationDuration="0ms">

    <mat-tab label="ダウンロード/申請" class="label">
      <div class="tab-field">
        <!-- 1. 商品属性を選択する -->
        <div class="mt-3">
          <h4 class="heading">1. 商品属性を選択する</h4>
        </div>
        <div class="px-4">
          <!-- 属性選択 -->
          <div class="select-div">
            <div class="converting-attribute-radio">
              <mat-radio-group [(ngModel)]="isChain">
                <mat-radio-button *ngIf="scopes?.showButtonAllChainAttr" color="primary" class="me-5" [value]="false">
                  全社商品属性
                </mat-radio-button>
                <mat-radio-button color="primary" [value]="true">チェーン個別商品属性</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="chain-select-autocomplete" *ngIf="isChain">
              <div>チェーン：</div>
              <mat-form-field class="select-chain" floatLabel="never" appearance="fill">
                <input type="text" placeholder="選択してください" matInput [formControl]="chainListControl"
                  [matAutocomplete]="autoComplete" />
                <mat-autocomplete #autoComplete [displayWith]="autoCcompleteDisplay.bind(this)">
                  <mat-option *ngFor="let option of filteredChainList | async" [value]="option.chainCd">
                    {{option.chainCd}} {{ option.chainName }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
        </div>
        <!-- 2. 現在の設定ファイルをダウンロードする -->
        <div class="mt-5">
          <div>
            <h4 class="heading">2. 現在の設定ファイルをダウンロードする</h4>
          </div>
        </div>
        <div class="px-4">
          <div class="ms-4 mt-5 download-button">
            <button mat-raised-button color="primary" (click)="clickDownloadCovertingAttributeFile()">
              <i class="fa fa-download"></i>
              ダウンロード
            </button>
          </div>
          <div class="d-flex px-1 py-1 mt-5">
            <div class="gray-back">
              任意
            </div>
            <div class="ms-3">
              アップロード前に変換ルールのテストを実行することが出来ます
            </div>
            <div class="ms-5">
              <mat-checkbox color="primary" [(ngModel)]="isTest">
                テスト実行する
              </mat-checkbox>
            </div>
          </div>
          <div class="upload-button mt-5 ms-4" *ngIf="!isTest">
            <button mat-raised-button color="warn" (click)="applyOnServicenow()">
              アップロード申請
            </button>
          </div>
        </div>
        <!-- 3. テスト実行する -->
        <div class="mt-5" *ngIf="isTest">
          <div>
            <h4 class="heading">3. テスト実行する</h4>
          </div>
          <div class="px-4">
            <div>
              <h5 class="mt-5">属性定義ファイルを選択する</h5>
              <div class="mt-4">
                <mat-radio-group [(ngModel)]="define">
                  <mat-radio-button color="primary" class="me-5" value="new">
                    編集した属性定義ファイルで実行する（※要アップロード）
                  </mat-radio-button>
                  <mat-radio-button color="primary" value="used">
                    テストしない
                  </mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="mt-4 upload-control-filed" [ngClass]="{ 'prevent-touch': define==='used' }">
                <app-upload-file class="file-drop-field" (fileEvent)="setCovertingAttributeFile($event, 'define')"
                  [acceptExtension]="allowedExtension">
                </app-upload-file>
              </div>
            </div>
            <div>
              <h5 class="mt-5">変換ルールファイルを選択する</h5>
              <div class="mt-4">
                <mat-radio-group [(ngModel)]="rule">
                  <mat-radio-button color="primary" class="me-5" value="new">
                    編集した変換ルールファイルで実行する（※要アップロード）
                  </mat-radio-button>
                  <mat-radio-button color="primary" value="used">
                    テストしない
                  </mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="mt-4 upload-control-filed" [ngClass]="{ 'prevent-touch': rule==='used' }">
                <app-upload-file class="file-drop-field" (fileEvent)="setCovertingAttributeFile($event, 'rule')"
                  [acceptExtension]="allowedExtension">
                </app-upload-file>
              </div>
            </div>
            <div class="mt-5">
              <mat-checkbox color="primary" [(ngModel)]="testConvert">属性変換を試しに実行する</mat-checkbox>
            </div>
            <div [ngClass]="{ 'prevent-touch': !testConvert }">
              <h5 class="mt-5">属性変換を試しに実行する商品マスタを選択する</h5>
              <div class="mt-4">
                <mat-radio-group [(ngModel)]="test">
                  <mat-radio-button color="primary" class="me-5" value="new">
                    サンプルファイルの商品マスタで実行する（※要アップロード）
                  </mat-radio-button>
                  <mat-radio-button color="primary" value="used">
                    現在の商品マスタ全商品で実行する
                  </mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="mt-4 upload-control-filed" [ngClass]="{ 'prevent-touch': test==='used' }">
                <app-upload-file class="file-drop-field" (fileEvent)="setCovertingAttributeFile($event, 'test')"
                  [acceptExtension]="allowedExtension">
                </app-upload-file>
              </div>
            </div>
            <div class="mt-5 message-content-field">
              <div class="message-title">メッセージ</div>
              <div class="mt-2 upload-control-filed">
                <app-message-field #messageField class="message-field"></app-message-field>
              </div>
            </div>
            <div class="mt-5 upload-button-field">
              <button class="mx-3" mat-raised-button color="primary" (click)="testConvertGoodsAttributeFile()">
                テスト実行
              </button>
              <div class="upload-button">
                <button mat-raised-button color="warn" (click)="applyOnServicenow()">
                  アップロード申請
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
    <!-- AIC -->
    <mat-tab *ngIf="scopes?.showTabUploadGoodsAttr" label="アップロード" class="label">
      <div class="tab-field">
        <!-- 1. 商品属性を選択する -->
        <div class="mt-3">
          <h4 class="heading">1. 商品属性を選択する</h4>
        </div>
        <div class="px-4">
          <!-- 属性選択 -->
          <div class="select-div">
            <div class="converting-attribute-radio">
              <mat-radio-group [(ngModel)]="isChain">
                <mat-radio-button color="primary" class="me-5" [value]="false">
                  全社商品属性
                </mat-radio-button>
                <mat-radio-button color="primary" [value]="true">チェーン個別商品属性</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="chain-select-autocomplete" *ngIf="isChain">
              <div>チェーン：</div>
              <mat-form-field class="select-chain" floatLabel="never" appearance="fill">
                <input type="text" placeholder="選択してください" matInput [formControl]="chainListControl"
                  [matAutocomplete]="autoComplete" />
                <mat-autocomplete #autoComplete [displayWith]="autoCcompleteDisplay.bind(this)">
                  <mat-option *ngFor="let option of filteredChainList | async" [value]="option.chainCd">
                    {{option.chainCd}} {{ option.chainName }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
          </div>
        </div>
        <!-- 2. 現在の設定ファイルをダウンロードする -->
        <div class="mt-5">
          <div>
            <h4 class="heading">2. 現在の設定ファイルをダウンロードする</h4>
          </div>
        </div>
        <div class="px-4">
          <div class="ms-4 mt-5 download-button">
            <button mat-raised-button color="primary" (click)="clickDownloadCovertingAttributeFile()">
              <i class="fa fa-download"></i>
              ダウンロード
            </button>
          </div>
        </div>
        <!-- 3. 本番適用ファイルを選択する -->
        <div class="mt-5">
          <div>
            <h4 class="heading">3. 本番適用ファイルを選択する</h4>
          </div>
          <div>
            <h5 class="mt-5">属性定義ファイルを選択する</h5>
            <div class="mt-4">
              <mat-radio-group [(ngModel)]="define">
                <mat-radio-button color="primary" class="me-5" value="new">
                  編集した属性定義ファイルを適用する（※要アップロード）
                </mat-radio-button>
                <mat-radio-button color="primary" value="used">
                  適用しない
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="mt-4 upload-control-filed" [ngClass]="{ 'prevent-touch': define==='used' }">
              <app-upload-file class="file-drop-field" (fileEvent)="setCovertingAttributeFile($event, 'define')"
                [acceptExtension]="allowedExtension">
              </app-upload-file>
            </div>
          </div>
          <div>
            <h5 class="mt-5">変換ルールファイルを選択する</h5>
            <div class="mt-4">
              <mat-radio-group [(ngModel)]="rule">
                <mat-radio-button color="primary" class="me-5" value="new">
                  編集した変換ルールファイルを適用する（※要アップロード）
                </mat-radio-button>
                <mat-radio-button color="primary" value="used">
                  適用しない
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="mt-4 upload-control-filed" [ngClass]="{ 'prevent-touch': rule==='used' }">
              <app-upload-file class="file-drop-field" (fileEvent)="setCovertingAttributeFile($event, 'rule')"
                [acceptExtension]="allowedExtension">
              </app-upload-file>
            </div>
          </div>
          <!-- 4. テスト実行する -->
          <div class="mt-5">
            <h4 class="heading">4. テスト実行する</h4>
          </div>
          <div>
            <h5 class="mt-5">属性変換を試しに実行する商品マスタを選択する</h5>
            <div class="mt-4">
              <mat-radio-group [(ngModel)]="test">
                <mat-radio-button color="primary" class="me-5" value="new">
                  サンプルファイルの商品マスタで実行する（※要アップロード）
                </mat-radio-button>
                <mat-radio-button color="primary" value="used">
                  現在の商品マスタ全商品で実行する
                </mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="mt-4 upload-control-filed" [ngClass]="{ 'prevent-touch': test==='used' }">
              <app-upload-file class="file-drop-field" (fileEvent)="setCovertingAttributeFile($event, 'test')"
                [acceptExtension]="allowedExtension">
              </app-upload-file>
            </div>
          </div>
          <div class="mt-5 message-content-field">
            <div class="message-title">メッセージ</div>
            <div class="mt-2 upload-control-filed">
              <app-message-field #messageField class="message-field"></app-message-field>
            </div>
          </div>
          <div class="mt-5 upload-button-field">
            <button class="mx-3" mat-raised-button color="primary" (click)="testConvertGoodsAttributeFile()">
              テスト実行
            </button>
            <div class="upload-button">
              <button mat-raised-button color="warn" (click)="applyConvertGoodsAttributeFile()">
                本番適用
              </button>
            </div>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<!-- 非表示ダウンロードリンク -->
<a #downloadLinkRef style="display: none">非表示リンク</a>

import { AppAuthService } from './../auth/app-auth.service';
import { AuthorityViewModels, ScopeViewModel } from './authority.service.viewmodel';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DEBUG_AUTHORITY_STORAGE_KEY } from 'src/app/pages/authority-selector/authority-selector.component';

@Injectable({
  providedIn: 'root'
})
export class AuthorityService {
  private authority: AuthorityViewModels;
  public chainListByUser = [];

  constructor(
    private http: HttpClient,
    private auth: AppAuthService
  ) { }

  public async scopes(): Promise<ScopeViewModel> {
    const organization = environment.organization;
    const userId = await this.getUserId();
    // すでにある場合
    if (this.authority) {
      return new Promise<ScopeViewModel>((res, rej) => { res(this.authority.scopes); });
    }
    // 取ってくる
    return new Promise<ScopeViewModel>((resolve, reject) => {
      this.getAuthority(organization, userId).subscribe(x => {
        this.chainListByUser = x.chainListByUser;
        resolve(x.scopes);
      });
    });
  }

  public getAuthority(organization: string, id: string): Observable<AuthorityViewModels> {
    const httpOptions: any = {
      headers: new HttpHeaders({
        'contet-type': 'application/json',
      }),
      params: {
        'organization': organization,
        'userId': id
      }
    };
    return this.http.get(environment.apiEndPoint + 'api/home/get-authority', httpOptions) as Observable<any>;
  }

  /** ユーザーIDの取得 */
  public async getUserId(): Promise<string> {
    let user = await this.auth.userId().toPromise();

    if (environment.debugMenu === true) {
      const storage = localStorage.getItem(DEBUG_AUTHORITY_STORAGE_KEY);
      if (storage) {
        user = storage;
      }
    }

    return user;
  }
}

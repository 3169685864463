import { AuthorityService } from './services/authority/authority.service';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppAuthService } from './services/auth/app-auth.service';
import { MaintenanceMenuService } from './shared/maintenance-menu/maintenance-menu.service';
import { Title } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { concatMap, filter, take, timeout } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  isIframe = false;
  public isLogined = false;

  constructor(
    private authorityService: AuthorityService,
    private auth: AppAuthService,
    private titleObj: Title) { }

  /** 初期化イベント */
  ngOnInit() {
    let title = this.titleObj.getTitle();
    title = title.replace('{0}', environment.organization);
    this.titleObj.setTitle(title);
    this.auth.loginChanged.subscribe(status => {
      // すでにログイン状態だったら何もしない
      if (!status || this.isLogined) {
        return;
      }

      this.initlaize();
    });

    this.initlaize();
  }

  /** 初期化処理 */
  private initlaize() {
    this.auth.loginStatus().subscribe(login => {
      console.log(Date.now(), 'initlaize', login);
      if (!login) {
        this.auth.login();
        return;
      }

      this.isLogined = true;
    });
  }
}

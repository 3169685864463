import { ScopeViewModel } from './../../services/authority/authority.service.viewmodel';
import { MessageFieldComponent } from 'src/app/shared/message-field/message-field.component';
import { RegisterGoodsService } from './register-goods.service';
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { RouterService } from 'src/app/services/router/router.service';
import { MaintenanceMenuService } from 'src/app/shared/maintenance-menu/maintenance-menu.service';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { RegisterGoodsMasterConditionModel, RegisterGoodsMasterDownloadQuery } from './register-goods.model';
import { AuthorityService } from 'src/app/services/authority/authority.service';
import { MatSelectChange } from '@angular/material/select';

/** チェーン選択欄の入力項目 */
class ChainListFormInput {
  private chainList: any[];
  /** チェーンを含むか？ */
  public include = false;
  /** 表示のチェーン一覧 */
  public displayList: any[];
  /** 表示フィルター */
  public filter: Observable<any>;
  /** 入力フォーム */
  public form: FormControl = new FormControl();

  /**
   * チェーンリストを設定します
   * @param list チェーンリスト
   */
  public setChainList(list: any) {
    this.chainList = list;
    this.filter = this.form.valueChanges
      .pipe(
        startWith(''),
        map(value => this.filterChainList(value))
      );
  }

  /**
   * チェーンリストをフィルタリングします
   * @param filteringStr フィルタリングする文字列
   * @returns フィルタされたチェーンリストの一覧
   */
  private filterChainList(filteringStr: any): any[] {
    let filteredKeys = '';
    filteredKeys = filteringStr.toLowerCase();
    const filtered = this.chainList.filter(it => it.chainName.toLowerCase().includes(filteredKeys) || it.chainCd.includes(filteredKeys));
    return filtered;
  }

  /** 入力内容のチェック */
  public valid(): boolean {
    if (!this.include || this.form.value) {
      return true;
    }
    return false;
  }
}

@Component({
  selector: 'app-register-goods',
  templateUrl: './register-goods.component.html',
  styleUrls: ['./register-goods.component.scss']
})
export class RegisterGoodsComponent implements OnInit, AfterViewInit {
  /** メッセージ欄 */
  @ViewChild(MessageFieldComponent, { static: false }) messageFieldComponent: MessageFieldComponent;
  /** ダウンロードリンク */
  @ViewChild('downloadLinkRef') public downloadLinkRef: ElementRef;
  /** アップロードファイル */
  private FileData: NgxFileDropEntry;
  /** アップロードファイルの拡張子フィルタ */
  private allowedExtension = '.xlsx';
  /* チェーンリスト */
  public chainList = [];
  /** テンプレートダウンロードのチェーン選択欄 */
  public templeteChain = new ChainListFormInput();
  /** 商品マスタダウンロードのチェーン選択欄 */
  public goodsMasterChain = new ChainListFormInput();
  /** アップロードファイルのチェーン選択欄 */
  public uploadChain = new ChainListFormInput();
  /** 絞り込み条件一覧 */
  private searchConditions: RegisterGoodsMasterConditionModel[] = [];
  public displaySearchConditions: RegisterGoodsMasterConditionModel[] = [];
  /** コード列含めるか？ */
  public includeCode = true;
  /** 権限スコープ */
  public scopes: ScopeViewModel;
  /**
   * コンストラクタ
   */
  constructor(
    public maintenanceMenuService: MaintenanceMenuService,
    private routerService: RouterService,
    private registerGoodsService: RegisterGoodsService,
    private authorityService: AuthorityService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.getScopes();
    this.routerService.setPreviousPage();
    this.registerGoodsService.initialize(environment.organization).subscribe(res => {
      // チェーンリスト取得
      this.chainList = res.chainList;

      this.templeteChain.setChainList(this.chainList);
      this.goodsMasterChain.setChainList(this.chainList);
      this.uploadChain.setChainList(this.chainList);
    });

    // 絞り込み条件
    this.registerGoodsService.getGoodsMasterCondition(environment.organization).subscribe(res => {
      this.searchConditions = res;
      if (this.searchConditions) {
        // 列挙型に表示用オプションを設定する
        this.searchConditions.filter(x => x.type === 'enum').forEach(x => x.displayOptions = x.options);
        this.searchConditions.forEach(x => x.form = new FormControl());

        // 親判定を入れる
        const enums = this.searchConditions.filter(x => x.visible && x.type === 'enum');
        enums.forEach(x => {
          // 親であるか判定
          if (x.parentName) {
            // searchConditions 例え表示されない親が居たとしても、その祖が表示されるために親がいる
            const parent = this.searchConditions.find(y => y.displayName === x.parentName);
            if (parent) {
              return;
            }
          } else {
            // searchConditions 例え表示されない子がいたとしても、その孫が表示されるために子がいる
            const child = this.searchConditions.find(y => y.parentName === x.displayName);
            if (!child) {
              return;
            }
          }

          x.isParent = true;
        });

        // 同一列にしたいやつ
        const display = [];
        this.searchConditions.forEach(x => {
          //  2列表示が前提なので、列数変更する場合はここも変更
          const colIdx = display.length % 2;
          if (x.isParent === true && colIdx != 0) {
            display.push({});
          }
          display.push(x);
        });
        this.displaySearchConditions = display;
      }
    });
  }

  private async getScopes() {
    this.scopes = await this.authorityService.scopes();
  }

  ngAfterViewInit(): void {
  }

  /**
   * アップロードファイル設定
   * @param file ファイル
   */
  public setFile(file: NgxFileDropEntry) {
    // Excelファイル未選択
    if (file !== null && !file.relativePath.endsWith(this.allowedExtension)) {
      this.toastr.error('Excelファイル以外が選択されています。ファイルを再選択してください", "Excelファイル非選択');
      return;
    }
    this.FileData = file;
  }

  /**
   * ファイルのアップロード処理
   */
  public uploadFile() {
    const formData = new FormData();
    // ファイル未選択
    if (!this.FileData || !this.FileData.fileEntry.isFile) {
      this.toastr.error('商品マスタファイルが選択されていません。', '商品マスタファイル未選択');
      return;
    }

    // チェーン選択
    if (this.uploadChain.include) {
      const chain = this.chainList.find(x => x.chainCd === this.uploadChain.form.value);
      if (!chain) {
        this.toastr.error('チェーンを選択してください', 'アンマッチ商品エラー');
        return;
      }
      formData.append('chainCd', chain.chainCd);
    }
    formData.append('organization', environment.organization);

    const fileEntry = this.FileData.fileEntry as FileSystemFileEntry;
    fileEntry.file((file: File) => {
      formData.append('file', file, this.FileData.relativePath);

      this.registerGoodsService.uploadGoodsList(formData).subscribe(res => {
        this.messageFieldComponent.pushMessage(res);
        if (res.fileName) {
          this.downloadUpdateFile(res.fileName);
        }

        // エラーある場合ファイルダウンロードせずに終了
        if (res.errorCount > 0) {
          this.toastr.error(
            '商品マスタファイルのアップロードに失敗しました', 'アップロード失敗'
          );
        }
        // 警告あり
        else if (res.warningCount > 0) {
          this.toastr.warning(
            '商品マスタファイルのアップロードで警告があります', 'アップロード警告'
          );
        }
        // 正常終了
        else {
          this.toastr.info(
            '商品マスタファイルのアップロードが完了しました', 'アップロード完了'
          );
        }
      });
    });
  }

  /**
   * 指定された商品差分ファイルをダウンロードします
   * @param fileName ファイル名（ファイルパス）
   */
  private downloadUpdateFile(fileName: string) {
    this.registerGoodsService.downloadGoodsList(fileName, '商品マスタ変更ファイル', this.downloadLinkRef);
  }

  /**
   * ServiceNowの申請ページを開く
   */
  public applyOnServicenow() {
    window.open(environment.servicenow.masterMaintenanceURL, '_blank');
  }

  /**
   * ServiceNoの申請履歴ページを開く
   */
  public openApplyList() {
    window.open(environment.servicenow.myRequestsURL, '_blank');
  }

  /**
   * オートコンプリート表示処理
   */
  public autoCompleteDisplay(value: any) {
    if (!value) {
      return '';
    }
    const chain = this.chainList.find(x => x.chainCd === value);
    return `${chain.chainCd} ${chain.chainName}`;
  }

  /**
   * 列挙対選択変更イベント
   * @param event イベント引数
   * @param item 発生元条件情報
   * @returns なし
   */
  public changedEnumCondition(event: MatSelectChange, item: RegisterGoodsMasterConditionModel) {
    // 列挙以外はないと思うけど、念のため
    if (item.type !== 'enum') {
      return;
    }

    this.filterChildEnumCondition(event.value as string[], item);
  }

  /**
   * 子要素のオプションを絞り込みます
   * @param keys 絞り込み対象の親キー一覧
   * @param src 絞り込み対象の親条件
   * @returns なし
   */
  private filterChildEnumCondition(keys: string[], src: RegisterGoodsMasterConditionModel) {
    // 子条件式
    const children = this.searchConditions.filter(x => x.parentName === src.displayName);
    if (!children) {
      return;
    }

    // 選択中の親条件の値一覧
    const values = src.options.filter(x => keys.includes(x.key)).map(x => x.value);
    children.forEach(child => {
      if (values && values.length > 0) {
        child.displayOptions = child.options.filter(x => values.includes(x.parent));
      } else {
        child.displayOptions = child.options;
      }

      // 孫にも適用
      let next = child.form.value as string[];
      if (!next || next.length <= 0) {
        next = child.displayOptions.map(x => x.key);
      }
      this.filterChildEnumCondition(next, child);
    });
  }

  /** 商品マスタのダウンロードボタンクリックイベント */
  public async clickedGoodsMasterDownload() {
    // 入力チェック
    if (this.goodsMasterChain.valid() === false) {
      this.toastr.error('チェーン名が選択されていません。', 'チェーン名未選択');
      return;
    }

    // ダウンロードモデル
    const model: RegisterGoodsMasterDownloadQuery = {
      userId: await this.authorityService.getUserId(),
      organization: environment.organization,
      chainCd: '',
      columns: [],
      inclodeCode: this.includeCode
    };

    // 検索列を設定
    this.searchConditions.forEach(x => {
      switch (x.type) {
        case 'enum':
          // 入力項目があること
          const ev = x.form.value as string[];
          if (!ev || ev.length <= 0) {
            return;
          }

          // 表示中項目であること
          const options = x.displayOptions.filter(f => ev.includes(f.key));
          if (!options || options.length <= 0) {
            return;
          }

          model.columns.push({
            name: x.name,
            parent: x.parentName,
            options: options,
            text: null
          });
          break;
        case 'txt':
          if (!x.form.value) {
            return;
          }

          model.columns.push({
            name: x.name,
            text: x.form.value,
            parent: null,
            options: null
          });
          break;
      }
    });

    // チェーン固有の場合
    let fileName = '';
    if (this.goodsMasterChain.include) {
      const chain = this.chainList.find(x => x.chainCd === this.goodsMasterChain.form.value);
      model.chainCd = chain.chainCd;
      fileName = `商品マスタ_${chain.chainName}`;
    } else {
      fileName = '商品マスタ_チェーン共通';
    }

    // ダウンロード開始
    this.registerGoodsService.downloadGoodsMaster(model, fileName, this.downloadLinkRef);
  }

  /** ダウンロードテンプレートボタンクリック */
  public async clickedDownLoadTemplate() {
    if (this.templeteChain.valid() === false) {
      this.toastr.error('チェーン名が選択されていません。', 'チェーン名未選択');
      return;
    }

    // ダウンロードモデル
    const model = {
      chainCd: '',
      organization: environment.organization
    };
    let fileName = '商品マスタ申請書テンプレート_チェーン共通';

    if (this.templeteChain.include) {
      const chain = this.chainList.find(x => x.chainCd === this.templeteChain.form.value);
      model.chainCd = chain.chainCd;
      fileName = '商品マスタ申請書テンプレート_' + chain.chainName;
    }

    // ダウンロード開始
    this.registerGoodsService.downloadGoodsMasterTemplate(model, fileName, this.downloadLinkRef);
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { AuthorityService } from 'src/app/services/authority/authority.service';
import { ScopeViewModel } from 'src/app/services/authority/authority.service.viewmodel';
import { MaintenanceMenuService } from 'src/app/shared/maintenance-menu/maintenance-menu.service';
import { environment } from 'src/environments/environment';
import { UnmatchStoresDownloadQuery } from '../../store-master/update-stores.model';
import { UpdateStoresService } from '../../store-master/update-stores.service';

type ChainSelectionTypes = 'charge_all' | 'charge_chain' | 'all' | 'chain';
/** チェーン選択の一覧 */
class ChainSelectionItem {
  public name: string;
  public value: ChainSelectionTypes;
  public charge: boolean;
  public selectable: boolean;
}

@Component({
  selector: 'app-download-unmatched-store',
  templateUrl: './download-unmatched-store.component.html',
  styleUrls: ['./download-unmatched-store.component.scss']
})
export class DownloadUnmatchedStoreComponent implements OnInit {
  @ViewChild('downloadLinkRef') public downloadLinkRef: ElementRef;
  /* フィルタリングされたチェーンリスト */
  public filteredChainList: Observable<{}>;
  /* 表示選択中のチェーンリスト */
  private chainList = [];
  /* 担当チェーンリスト */
  private chainListByUser = [];
  /* 全チェーン */
  private allChainList = [];
  /* チェーン選択オートコンプリートフォーム */
  public chainListControl = new FormControl();

  /** チェーン選択タイプ */
  public chainSelectionTypes: ChainSelectionItem[] = [
    { name: '全ての担当チェーンを表示', value: 'charge_all', charge: true, selectable: false },
    { name: '担当チェーンを選択', value: 'charge_chain', charge: true, selectable: true },
    { name: '全てのチェーンを表示', value: 'all', charge: false, selectable: false },
    { name: 'チェーンを選択', value: 'chain', charge: false, selectable: true },
  ];
  public frmChainSelectionType: FormControl = new FormControl('');
  /** チェーンの選択可否 */
  public chainSelectable = true;

  /** 権限スコープ */
  public scopes: ScopeViewModel;

  /**
   * コンストラクタ
   */
  constructor(
    public maintenanceMenuService: MaintenanceMenuService,
    private updateStoresService: UpdateStoresService,
    private authorityService: AuthorityService,
    private route: ActivatedRoute,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.initialize();

    // チェーン選択種類変更イベント
    this.frmChainSelectionType.valueChanges.subscribe(x => {
      const item = this.chainSelectionTypes.find(y => y.value === x);
      if (!item) {
        return;
      }
      this.chainSelectable = item.selectable;
      if (item.charge === true) {
        this.chainList = this.authorityService.chainListByUser;
        this.filteredChainList = this.chainListControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this.autoCompleteFilter(value))
          );
      } else {
        this.chainList = this.allChainList;
        this.filteredChainList = this.chainListControl.valueChanges
          .pipe(
            startWith(''),
            map(value => this.autoCompleteFilter(value))
          );
      }

      this.chainListControl.setValue(null);
    });
  }

  private async initialize() {
    this.scopes = await this.authorityService.scopes();

    this.updateStoresService.initialize(environment.organization).subscribe(res => {
      // チェーンリスト取得
      this.chainList = res.chainList;
      this.allChainList = res.chainList;
      this.chainListByUser = this.authorityService.chainListByUser;

      // チェーンリストサジェスチョン設定
      this.filteredChainList = this.chainListControl.valueChanges
        .pipe(
          startWith(''),
          map(value => this.autoCompleteFilter(value))
        );

      // デフォルト表示リスト設定
      if (this.scopes.showChainCharge) {
        this.frmChainSelectionType.setValue('charge_chain');
      } else {
        this.frmChainSelectionType.setValue('chain');
        this.chainSelectionTypes = this.chainSelectionTypes.filter(x => x.charge === false);
      }

      this.route.paramMap.subscribe((p: ParamMap) => {
        const chain = this.allChainList.find(x => x.chainCd === p.get('chainCd'));
        if (!chain) {
          return;
        }

        if (this.chainListByUser.find(x => x.chainCd === chain.chainCd) && this.scopes.showChainCharge) {
          this.frmChainSelectionType.setValue('charge_chain');
          this.chainListControl.setValue(chain.chainCd);
        } else {
          this.frmChainSelectionType.setValue('chain');
          this.chainListControl.setValue(chain.chainCd);
        }
      });
    });
  }

  /**
   * オートコンプリート表示処理
   */
  public autoCompleteDisplay(value: any) {
    if (!value) {
      return '';
    }
    const chain = this.chainList.find(x => x.chainCd === value);
    return `${chain.chainCd} ${chain.chainName}`;
  }

  public clearChainSelection(e: Event) {
    this.chainListControl.reset();
    e.stopPropagation();
  }

  /**
   * オートコンプリートフィルタリング
   * @param filteringStr フィルタリングする文字列
   */
  private autoCompleteFilter(filteringStr: any) {
    if (filteringStr) {
      const filteredKeys = filteringStr.toLowerCase();
      const filtered = this.chainList.filter(it => it.chainName.toLowerCase().includes(filteredKeys) || it.chainCd.includes(filteredKeys));
      return filtered;
    } else {
      return this.chainList;
    }
  }

  /** ダウンロードボタンクリック */
  public async clickedDownLoad() {
    const model: UnmatchStoresDownloadQuery =
    {
      organization: environment.organization,
      chainCdList: [],
      uri: ''
    };
    let fileName = '';

    // チェーン固有の場合
    if (this.chainSelectable) {
      const chain = this.chainList.find(x => x.chainCd === this.chainListControl.value);
      if (!chain) {
        this.toastr.error('チェーンを選択してください', 'チェーン非選択');
        return;
      }
      model.chainCdList.push(chain.chainCd);
      fileName = `アンマッチ店舗リスト_${chain.chainName}`;

      // 全ての担当チェーンの場合
    } else if (this.frmChainSelectionType.value === 'charge_all') {
      this.chainList.forEach(x => {
        model.chainCdList.push(x.chainCd);
      });
      fileName = 'アンマッチ店舗リスト_全担当チェーン';

      // 全てのチェーンの場合
    } else if (this.frmChainSelectionType.value === 'all') {
      this.allChainList.forEach(x => {
        model.chainCdList.push(x.chainCd);
      });
      fileName = 'アンマッチ店舗リスト_全チェーン';
    }

    this.updateStoresService.checkUnmatchedStoresListExist(model).subscribe(x => {
      if (!x.exist) {
        this.toastr.info(x.message);
        return;
      }
      model.uri = x.uri;

      // ダウンロード開始
      this.updateStoresService.downloadUnmatchedStoresList(model, fileName, this.downloadLinkRef);
    });
  }

  /**
   * ServiceNowの申請ページを開く
   */
  public applyOnServicenow() {
    window.open(environment.servicenow.solveUnmatchedStoresURL, '_blank');
  }

  /**
   * ServiceNoの申請履歴ページを開く
   */
  public openApplyList() {
    window.open(environment.servicenow.myRequestsURL, '_blank');
  }
}

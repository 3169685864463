import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { TableauEmbedComponent } from '../tableau-embed/tableau-embed.component';

@Component({
  selector: 'app-download-button',
  templateUrl: './download-button.component.html',
  styleUrls: ['./download-button.component.scss']
})
export class DownloadButtonComponent implements OnInit {
  /** true:ダウンロード中 */
  public downloading = false;

  /** ダウンロードファイル名 */
  @Input()
  public fileName: string;

  /** ダウンロード元のTableau埋め込みコンポーネント */
  @Input()
  public tableauEmbed: TableauEmbedComponent;

  constructor(
    private toastr: ToastrService) { }

  ngOnInit(): void {
  }

  /** ダウンロードクリックイベント */
  public async clickedDownLoad() {
    this.tableauEmbed.downloadExcel();
  }
}

<div class="main px-3 py-3">
  <div class="page-title-bg">
  </div>
  <div class="page-title">
    <div class="title-container">個別権限申請</div>
  </div>
  <mat-tab-group class="page-body mat-tab-fill-height" animationDuration="0ms">
    <mat-tab  label="ダウンロード/申請" class="label">
      <div class="tab-body">
        <h5 class="mt-4 mb-0">権限をダウンロード/申請する</h5>


        <div class="ps-4">



          <div *ngIf="scopes?.showButtonDownloadChainManager" class="heading">チェーン担当</div>
          <div *ngIf="scopes?.showButtonDownloadChainManager" class="ps-4 mt-3">
            <div class="spinner" *ngIf="downloading">
              <mat-spinner [diameter]="35"></mat-spinner>
            </div>
            <button [disabled]="downloading" mat-raised-button color="primary" (click)="downloadChainManager()">
              <i class="fa fa-download"></i>
              権限情報ダウンロード
            </button>
            <button  mat-raised-button color="accent" class="apply-button" (click)="openServiceNow(chainManager)">
              申請ページを開く
            </button>
          </div>

            <div *ngIf="scopes?.showButtonDownloadPosManager" class="heading">地区POS担当</div>
            <div *ngIf="scopes?.showButtonDownloadPosManager" class="ps-4 mt-3">
              <div class="spinner" *ngIf="downloading">
                <mat-spinner [diameter]="35"></mat-spinner>
              </div>
              <button [disabled]="downloading" mat-raised-button color="primary" (click)="downLoadPosManager()">
                <i class="fa fa-download"></i>
                権限情報ダウンロード
              </button>
              <button  mat-raised-button color="accent" class="apply-button" (click)="openServiceNow(posManager)">
                申請ページを開く
              </button>
            </div>

          <div *ngIf="scopes?.showButtonDownloadChainAdmin" class="heading">チェーン本部担当</div>
          <div *ngIf="scopes?.showButtonDownloadChainAdmin" class="ps-4 mt-3">
            <div class="spinner" *ngIf="downloading">
              <mat-spinner [diameter]="35"></mat-spinner>
            </div>
            <button [disabled]="downloading" mat-raised-button color="primary" (click)="downLoadChainAdmin()">
              <i class="fa fa-download"></i>
              権限情報ダウンロード
            </button>
            <button *ngIf="scopes?.showButtonRequestChainAdmin" mat-raised-button color="accent" class="apply-button" (click)="openGeneralRequest()">
              申請ページを開く
            </button>
          </div>

          <div class="heading">主管部担当</div>
          <div class="ps-4 mt-3">
            <div class="spinner" *ngIf="downloading">
              <mat-spinner [diameter]="35"></mat-spinner>
            </div>
            <button [disabled]="downloading" mat-raised-button color="primary" (click)="downLoadBusinessAdmin()">
              <i class="fa fa-download"></i>
              権限情報ダウンロード
            </button>
            <button  mat-raised-button color="accent" class="apply-button" (click)="openServiceNow(businessAdmin)">
              申請ページを開く
            </button>
          </div>

          <div class="heading">AIC業務委託担当</div>
          <div class="ps-4 mt-3">
            <div class="spinner" *ngIf="downloading">
              <mat-spinner [diameter]="35"></mat-spinner>
            </div>
            <button [disabled]="downloading" mat-raised-button color="primary" (click)="downLoadBusinessAgent()">
              <i class="fa fa-download"></i>
              権限情報ダウンロード
            </button>
            <button  mat-raised-button color="accent" class="apply-button" (click)="openServiceNow(businessAgent)">
              申請ページを開く
            </button>
          </div>







        </div>

        <h5 class="mt-5">権限の申請状況を確認する</h5>

<div class="ps-4 mt-2">

  <div class="container-footer ps-4">
    <button mat-raised-button class="button-history" color="primary" (click)="openHistory()">
      申請状況確認
    </button>
  </div>

</div>




      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<!-- 非表示ダウンロードリンク -->
<a #downloadLinkRef style="display: none">非表示リンク</a>

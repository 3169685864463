import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppAuthService } from '../auth/app-auth.service';
import { LogService } from '../log/log.service';

@Injectable()
export class CustomErrorHandler implements ErrorHandler {
  constructor(
    private logService: LogService,
    private auth: AppAuthService,
  ) {}

  handleError(e) {
    console.error(e);

    if (e instanceof Error) {
      this.log(e.message + ':' + e.stack);
    } else {
      this.log('type not error:' + e);
    }
  }

  private async log(e: string) {
    const log = {
      organization: environment.organization,
      user: await this.auth.userId().toPromise(),
      exception: e
    };

    this.logService.writeLog({
      LogSeverity: 'Warning',
      LogText: JSON.stringify(log)
    });
  }
}

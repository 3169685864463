<div class="tab-body">
  <div class="heading">チェーンを選択する</div>
  <div class="select-chain-container ps-4 mt-3">
    <!-- チェーン選択種類 -->
    <mat-form-field appearance="fill" class="select-chain-type">
      <mat-label>表示するリストの選択</mat-label>
      <mat-select [formControl]="frmChainSelectionType">
        <mat-option *ngFor="let item of chainSelectionTypes" [value]="item.value">
          {{item.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <!-- チェーン選択 -->
    <mat-form-field floatLabel="never" class="select-chain-field ms-2"
      [ngClass]="{ 'prevent-touch': chainSelectable !== true }" appearance="fill">
      <mat-label>チェーンの選択</mat-label>
      <input type="text" placeholder="選択してください" matInput [formControl]="chainListControl"
        [matAutocomplete]="autoComplete">
      <mat-autocomplete #autoComplete="matAutocomplete" [displayWith]="autoCompleteDisplay.bind(this)">
        <mat-option *ngFor="let option of filteredChainList | async" [value]="option.chainCd">
          {{option.chainCd}} {{ option.chainName }}
        </mat-option>
      </mat-autocomplete>
      <button *ngIf="chainListControl.value" matSuffix mat-icon-button aria-label="Clear"
        (click)="clearChainSelection($event)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="heading">店舗アンマッチリストをダウンロードする</div>
  <div class="ps-4 mt-3">
    <button mat-raised-button color="primary" (click)="clickedDownLoad()">
      <i class="fa fa-download"></i>
      ダウンロード
    </button>
  </div>
  <div *ngIf="scopes?.showButtonApplyUnmatchedStores || scopes?.showButtonCheckApplyUnmatchedStores" class="heading">
    店舗のアンマッチ登録を申請する</div>
  <div class="ps-4 mt-3">
    <button *ngIf="scopes?.showButtonApplyUnmatchedStores" mat-raised-button color="accent" class="apply-button"
      (click)="applyOnServicenow()">
      アップロード申請
    </button>
    <button *ngIf="scopes?.showButtonCheckApplyUnmatchedStores" mat-raised-button color="primary" class="ms-4" (click)="openApplyList()">
      申請状況確認
    </button>
  </div>
</div>

<a #downloadLinkRef style="display: none">非表示リンク</a>

import { EventEmitter, Injectable, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService, User } from '@auth0/auth0-angular';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { concat, Observable, of } from 'rxjs';
import { catchError, concatMap, concatMapTo, filter, map, mergeMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthConfirmDialogComponent } from './auth-confirm-dialog/auth-confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class AppAuthService {
  private loginEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  public dialogRef: TemplateRef<any>;

  /**
   * ログイン状態変更イベント
   * true: ログイン状態, false: ログオフ状態
   */
  public get loginChanged(): Observable<boolean> {
    return this.loginEvent.asObservable();
  }

  constructor(
    private auth: AuthService,
    public router: Router,
    private dialog: MatDialog
  ) {
  }

  /**
   * ログイン状態を取得します
   * @returns true: ログイン状態, false: ログオフ状態
   */
  public loginStatus(): Observable<boolean> {
    return this.auth.isAuthenticated$.pipe(take(1));
  }

  /**
   * ユーザーIDを取得します
   * これは認証で使うユーザーIDで、権限はAuthorityServiceのユーザーIDを使ってください
   * @returns ユーザID
   */
  public userId(): Observable<string> {

    // ユーザーIDを取得本体
    const ob = this.auth.user$.pipe(
      filter(x => !!x),
      take(1),
      map(x => {
        const key = environment.appAuth0.keyUserId;

        // UserIDのキーあるんだっけ？
        const keys = Object.keys(x);
        if (keys.includes(key) === false) {
          return null;
        }

        const userId = x[key] as string;
        if (userId.indexOf('@') < 0) {
          // メールアドレスではない = 一般ユーザー
          return userId;
        } else {
          // メールドレスである = ゲストユーザー ("ゲストメールアドレス#EXT#@テナント" みたいなメールアドレスが入ってる)
          // emailから元のメールアドレス取る
          return x.email.split('@')[0];
        }
      })
    );

    // ユーザーIDは認証が通ってから取得する
    return this.auth.isAuthenticated$.pipe(
      filter(x => x === true),
      take(1),
      concatMapTo(ob)
    );
  }

  /** ログイン処理 */
  public login() {
    console.log(Date.now(), 'AppAuthService target', window.location.href);
    const option = {} as any;
    if (window.location.pathname.startsWith('/login')) {
      // ログイン遷移後画面からはログインしない
      return;
    } else {
      const path = `${window.location.pathname}${window.location.search}${window.location.hash}`;
      console.log(Date.now(), 'AppAuthService target', path);
      option.appState = {
        target: path
      };
    }

    console.log(Date.now(), 'AppAuthService loginWithRedirect', option);
    this.auth.loginWithRedirect(option);
  }

  /** ログアウト処理 */
  public logout() {
    this.auth.logout();
  }

  /**
   * トークンを取得します
   * @returns トークン
   */
  public getToken(): Observable<string> {
    // 認証済む前に呼ばれることがあるとエラーになるので、認証確認してからトークン取得する
    return this.auth.isAuthenticated$.pipe(
      filter(x => x === true),
      take(1),
      concatMap(x => {
        return this.getTokenRetryModel();
      })
    );
  }

  /**
   * ホスト名を調べて最適な方法でトークンを取得します
   * @returns トークン
   */
  private getTokenMatchingModel(): Observable<string> {
    // ローカル実行の場合はユーザーの確認が必要
    const reg = environment.auth0.redirectUri.match(/(http|https):\/\/localhost/);
    if (reg) {
      console.log(Date.now(), 'intercept getAccessTokenWithPopup');
      return this.auth.getAccessTokenWithPopup({
        audience: environment.auth0.audience
      });
    } else {
      console.log(Date.now(), 'intercept getAccessTokenSilently');
      return this.auth.getAccessTokenSilently({
        audience: environment.auth0.audience
      });
    }
  }

  /**
   * 取得方法を試しながらトークンを取得します
   * @returns トークン
   */
  private getTokenRetryModel(): Observable<string> {
    return this.auth.getAccessTokenSilently({
      audience: environment.auth0.audience
    }).pipe(
      map(x => { console.log(Date.now(), 'intercept getAccessTokenSilently'); return x; }),
      catchError(x => {
      console.log(Date.now(), 'getTokenRetryModel catchError', x);

      // ポップアップモデルで試す
      return this.getTokenWithDialog();
    }));
  }

  /**
   * ダイアログを使用したトークン取得
   * @returns トークン
   */
  private getTokenWithDialog(): Observable<string> {
    return new Observable<string>(observer => {
      const dlg = this.dialog.open(AuthConfirmDialogComponent);
      const instance = dlg.componentInstance;

      instance.pressedOk.subscribe(() => {
        dlg.close();

        this.auth.getAccessTokenWithPopup({
          audience: environment.auth0.audience
        }).subscribe(
          ok => {
            observer.next(ok);
          },
          ng => {
            observer.error('ダイアログからのトークン取得に失敗しました');
          },
          () => {
            observer.complete();
          })
      });
    });
  }

  /**
   * 認証ログイン後のリダイレクト処理
   * （リダイレクトページで呼んでください）
   */
  public handleRedirect() {
    console.log(Date.now(), 'AppAuthService handleRedirect', window.location.href);
    this.auth.handleRedirectCallback(window.location.href).subscribe(ok => {
      console.log(Date.now(), 'handleRedirectCallback ok', ok);
      this.loginOk();
    }, ng => {
      console.log(Date.now(), 'handleRedirectCallback ng', ng);
      this.loginNg();
    });
  }

  /** ログイン成功処理 */
  private loginOk() {
    console.log(Date.now(), 'login');
    this.auth.isAuthenticated$.subscribe(isAuthenticated => {
      console.log(Date.now(), 'isAuthenticated ok', isAuthenticated);
      if (!isAuthenticated) {
        // isAuthenticated は isLoading$ に依存するらしいが、この値が変わらなくても isAuthenticated は変化するので、ここではいったん false はスルーする
        return;
      }

      // ここでようやくログインできた
      this.loginEvent.emit(true);
      this.auth.appState$.subscribe(s => {
        console.log(Date.now(), 'appState', s);
        this.router.navigateByUrl(s.target);
      });

      if (environment.production === false) {
        this.auth.user$.pipe(take(1)).subscribe(x => {
          console.log(Date.now(), 'user', x);
        });

        this.auth.error$.subscribe(x => {
          console.log(Date.now(), 'auth service error', x);
        });

        this.auth.idTokenClaims$.subscribe(x => {
          console.log(Date.now(), 'idTokenClaims', x);
        });
      }

    }, ng => {
      console.log(Date.now(), 'isAuthenticated ng', ng);
      this.loginNg();
    });
  }

  /** ログイン失敗処理 */
  private loginNg() {
    this.loginEvent.emit(false);
    this.router.navigate(['/error', {queryParams: { 'errorCode': 401 }}]);
  }
}

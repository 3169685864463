import { ScopeViewModel } from './../../services/authority/authority.service.viewmodel';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { SolveUnmatchedGoodsService } from './solve-unmatched-goods.service';
import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { RouterService } from 'src/app/services/router/router.service';
import { MaintenanceMenuService } from 'src/app/shared/maintenance-menu/maintenance-menu.service';
import { MessageFieldComponent } from 'src/app/shared/message-field/message-field.component';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AuthorityService } from 'src/app/services/authority/authority.service';
import { UnmatchedGoodsDownloadQuery } from './solve-unmatched-goods.model';

type ChainSelectionTypes = 'charge_all' | 'charge_chain' | 'all' | 'chain';
/** チェーン選択の一覧 */
class ChainSelectionItem {
  public name: string;
  public value: ChainSelectionTypes;
  public charge: boolean;
  public selectable: boolean;
  public visible: boolean;
}

@Component({
  selector: 'app-solve-unmatched-goods',
  templateUrl: './solve-unmatched-goods.component.html',
  styleUrls: ['./solve-unmatched-goods.component.scss']
})
export class SolveUnmatchedGoodsComponent implements OnInit {
  @ViewChild(MessageFieldComponent, { static: false }) messageFieldComponent: MessageFieldComponent;
  @ViewChild('downloadLinkRef') public downloadLinkRef: ElementRef;
  private FileData: NgxFileDropEntry;
  private allowedExtension = '.xlsx';
  /* フィルタリングされたチェーンリスト */
  public filteredChainListForDownload: Observable<{}>;
  /* 表示対象のチェーンリスト */
  private chainList = [];
  /* 担当チェーンリスト */
  private chainListByUser = [];
  /* 全チェーン */
  private allChainList = [];
  public filteredChainListForUpload: Observable<{}>;
  /* チェーン選択オートコンプリートフォーム */
  public chainListControlForDownload = new FormControl();
  public chainListControlForUpload = new FormControl();
  /** 権限スコープ */
  public scopes: ScopeViewModel;

  /** チェーン選択タイプ */
  public chainSelectionTypes: ChainSelectionItem[] = [
    { name: '全ての担当チェーン', value: 'charge_all', charge: true, selectable: false, visible: false },
    { name: '担当チェーンを選択', value: 'charge_chain', charge: true, selectable: true, visible: false },
    { name: '全てのチェーン', value: 'all', charge: false, selectable: false, visible: true },
    { name: 'チェーンを選択', value: 'chain', charge: false, selectable: true, visible: true },
  ];
  public frmChainSelectionTypeForDownload: FormControl = new FormControl('');

  /** チェーンの選択可否 */
  public chainSelectableForDownload = true;
  public chainSelectableForUpload = false;

  /**
   * コンストラクタ
   */
  constructor(
    public maintenanceMenuService: MaintenanceMenuService,
    public solveUnmatchedGoodsService: SolveUnmatchedGoodsService,
    private routerService: RouterService,
    private authorityService: AuthorityService,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.routerService.setPreviousPage();
    this.initialize();
  }

  private async initialize() {
    // スコープ取得
    this.scopes = await this.authorityService.scopes();

    // 初期値取得
    this.solveUnmatchedGoodsService.initialize(environment.organization).subscribe(res => {
      // チェーンリスト取得
      this.chainList = res.chainList;
      this.allChainList = res.chainList;
      this.chainListByUser = this.authorityService.chainListByUser;

      // チェーンリストサジェスチョン設定
      this.filteredChainListForDownload = this.chainListControlForDownload.valueChanges
        .pipe(
          startWith(''),
          map(value => this.autoCompleteFilterForDownload(value))
        );
      this.filteredChainListForUpload = this.chainListControlForUpload.valueChanges
        .pipe(
          startWith(''),
          map(value => this.autoCompleteFilterForUpload(value))
        );

      // デフォルト表示リスト設定
      if (this.scopes.showChainCharge) {
        this.frmChainSelectionTypeForDownload.setValue('charge_chain');
      } else {
        this.chainSelectionTypes = this.chainSelectionTypes.filter(x => x.charge == false);
        this.frmChainSelectionTypeForDownload.setValue('chain');
      }

      this.route.paramMap.subscribe((p: ParamMap) => {
        const chain = this.allChainList.find(x => x.chainCd === p.get('chainCd'));
        if (!chain) {
          return;
        }

        if (this.chainListByUser.find(x => x.chainCd === chain.chainCd) && this.scopes.showChainCharge) {
          this.frmChainSelectionTypeForDownload.setValue('charge_chain');
          this.chainListControlForDownload.setValue(chain.chainCd);
        } else {
          this.frmChainSelectionTypeForDownload.setValue('chain');
          this.chainListControlForDownload.setValue(chain.chainCd);
        }
      });
    });

    // チェーン選択種類変更イベント
    this.frmChainSelectionTypeForDownload.valueChanges.subscribe(x => {
      const item = this.chainSelectionTypes.find(y => y.value === x);
      if (!item) {
        return;
      }

      this.chainSelectableForDownload = item.selectable;
      if (item.charge === true) {
        this.chainList = this.chainListByUser;
        this.filteredChainListForDownload = this.chainListControlForDownload.valueChanges
          .pipe(
            startWith(''),
            map(value => this.autoCompleteFilterForDownload(value))
          );
      } else {
        this.chainList = this.allChainList;
        this.filteredChainListForDownload = this.chainListControlForDownload.valueChanges
          .pipe(
            startWith(''),
            map(value => this.autoCompleteFilterForDownload(value))
          );
      }
      this.chainListControlForDownload.setValue(null);
    });
  }

  /**
   * アップロードファイル設定
   * @param file ファイル
   * @returns
   */
  public setFile(file: NgxFileDropEntry) {
    // Excelファイル未選択
    if (file !== null && !file.relativePath.endsWith(this.allowedExtension)) {
      this.toastr.error('Excelファイル以外が選択されています。ファイルを再選択してください", "Excelファイル非選択');
      return;
    }
    this.FileData = file;
  }

  /**
   * ファイルのアップロード処理
   */
  public uploadFile() {
    const formData = new FormData();
    // ファイル未選択
    if (!this.FileData || !this.FileData.fileEntry.isFile) {
      this.toastr.error('アンマッチ商品ファイルが選択されていません。', 'アンマッチ商品エラー');
      return;
    }

    if (this.chainSelectableForUpload) {
      const chain = this.allChainList.find(x => x.chainCd === this.chainListControlForUpload.value);
      if (!chain) {
        this.toastr.error('チェーンを選択してください', 'チェーン非選択');
        return;
      }
      formData.append('chainCd', chain.chainCd);
    }
    formData.append('organization', environment.organization);

    const fileEntry = this.FileData.fileEntry as FileSystemFileEntry;
    fileEntry.file((file: File) => {
      formData.append('file', file, this.FileData.relativePath);

      this.solveUnmatchedGoodsService.uploadUnmatchedGoodsList(formData).subscribe(res => {
        this.messageFieldComponent.pushMessage(res);
        if (res.fileName) {
          this.downloadUpdateFile(res.fileName);
        }

        // エラーあり
        if (res.errorCount > 0) {
          this.toastr.error(
            'アンマッチ商品ファイルのアップロードに失敗しました', 'アップロード失敗'
          );
        }
        // 警告あり
        else if (res.warningCount > 0) {
          this.toastr.warning(
            'アンマッチ商品ファイルのアップロードで警告があります', 'アップロード警告'
          );
        }
        // 正常終了
        else {
          this.toastr.info(
            'アンマッチ商品ファイルのアップロードが完了しました', 'アップロード完了'
          );
        }
      });
    });
  }

  /**
   * 指定されたアンマッチ商品差分ファイルをダウンロードします
   * @param fileName ファイル名（ファイルパス）
   */
  private downloadUpdateFile(fileName: string) {
    const el = this.downloadLinkRef.nativeElement as HTMLAnchorElement;
    el.download = null;

    this.solveUnmatchedGoodsService.downloadGoodsList(fileName, 'アンマッチ商品差分ファイル', this.downloadLinkRef);
  }

  /**
   * ServiceNowの申請ページを開く
   */
  public applyOnServicenow() {
    window.open(environment.servicenow.solveUnmatchedGoodsURL, '_blank');
  }

  /**
   * ServiceNoの申請履歴ページを開く
   */
  public openApplyList() {
    window.open(environment.servicenow.myRequestsURL, '_blank');
  }

  /**
   * オートコンプリート表示処理
   */
  public autoCompleteDisplayForDownload(value: any) {
    if (!value) {
      return '';
    }
    const chain = this.chainList.find(x => x.chainCd === value);
    return `${chain.chainCd} ${chain.chainName}`;
  }

  public autoCompleteDisplayForUpload(value: any) {
    if (!value) {
      return '';
    }
    const chain = this.allChainList.find(x => x.chainCd === value);
    return `${chain.chainCd} ${chain.chainName}`;
  }

  /**
   * オートコンプリートフィルタリング
   * @param filteringStr フィルタリングする文字列
   */
  private autoCompleteFilterForDownload(filteringStr: any) {
    if (filteringStr) {
      const filteredKeys = filteringStr.toLowerCase();
      const filtered = this.chainList.filter(it => it.chainName.toLowerCase().includes(filteredKeys) || it.chainCd.includes(filteredKeys));
      return filtered;
    } else {
      return this.chainList;
    }
  }

  private autoCompleteFilterForUpload(filteringStr: any) {
    if (filteringStr) {
      const filteredKeys = filteringStr.toLowerCase();
      const filtered = this.chainList.filter(it => it.chainName.toLowerCase().includes(filteredKeys) || it.chainCd.includes(filteredKeys));
      return filtered;
    } else {
      return this.allChainList;
    }
  }



  /** ダウンロードボタンクリック */
  public async clickedDownLoad() {
    const model: UnmatchedGoodsDownloadQuery =
    {
      organization: environment.organization,
      chainCdList: [],
      uri: ''
    };
    let fileName = '';

    // チェーン固有の場合
    if (this.chainSelectableForDownload) {
      const chain = this.chainList.find(x => x.chainCd === this.chainListControlForDownload.value);
      if (!chain) {
        this.toastr.error('チェーンを選択してください', 'チェーン非選択');
        return;
      }
      model.chainCdList.push(chain.chainCd);
      fileName = `アンマッチ商品リスト_${chain.chainName}`;

      // 全ての担当チェーンの場合
    } else if (this.frmChainSelectionTypeForDownload.value === 'charge_all') {
      this.chainList.forEach(x => {
        model.chainCdList.push(x.chainCd);
      });
      fileName = 'アンマッチ商品リスト_全担当チェーン';

      // 全てのチェーンの場合
    } else if (this.frmChainSelectionTypeForDownload.value === 'all') {
      this.allChainList.forEach(x => {
        model.chainCdList.push(x.chainCd);
      });
      fileName = 'アンマッチ商品リスト_全チェーン';
    }

    this.solveUnmatchedGoodsService.checkUnmatchedGoodsListExist(model).subscribe(x => {
      if (!x.exist) {
        this.toastr.info(x.message);
        return;
      }

      model.uri = x.uri;

      // ダウンロード開始
      this.solveUnmatchedGoodsService.downloadUnmatchedGoodsList(model, fileName, this.downloadLinkRef);
    });
  }

  public clearChainSelectionForDownload(e: Event) {
    this.chainListControlForDownload.reset();
    e.stopPropagation();
  }

  public clearChainSelectionForUpload(e: Event) {
    this.chainListControlForUpload.reset();
    e.stopPropagation();
  }
}

<div class="error-message">
  <div class="main-area border border-light">
    <h2>
      <i class="fa fa-exclamation-triangle" style="color: red">
        エラーが発生しました
      </i>
    </h2>

    <div class="mt-4">
      エラーが発生したため、ページが表示できません<br />
      <br />
      システム担当者にお問い合わせください
      <br />
      ({{ errorMessage }})
    </div>
  </div>
</div>

<div style="height: 100vh; width: 100vw">
  <ng-container *ngIf="!maintenanceMenuService.visibleMenu">
    <button class="menu-button" (click)="showMenu()">
      <i class="fas fa-bars"></i>
    </button>
  </ng-container>
  <div class="main-field">
    <app-maintenance-menu
      *ngIf="maintenanceMenuService.visibleMenu"
      class="maintenance-menu"
    ></app-maintenance-menu>
    <div
      [ngClass]="
        maintenanceMenuService.visibleMenu
          ? 'contents-field-with-menu'
          : 'contents-field-without-menu'
      "
    >
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<div class="main px-3 py-3">
  <div class="page-title-bg">
  </div>
  <div class="page-title">
    <div class="title-container">チェーン本部担当</div>
  </div>
  <mat-tab-group class="page-body mat-tab-fill-height" animationDuration="0ms">

    <mat-tab label="ダウンロード／申請" class="label">
      <div class="tab-body">
        <div class="heading">
          チェーン本部担当ファイルをダウンロードする
        </div>
        <div class="ps-4 mt-3">
          <button mat-raised-button color="primary" (click)="downloadRequestFile()">
            <i class="fa fa-download"></i>
            ダウンロード
          </button>
        </div>
        <div class="heading">申請する</div>
        <div class="ps-4 mt-3">
          <button mat-raised-button color="accent" class="apply-button">
            アップロード申請
          </button>
          <button mat-raised-button color="primary" class="ms-4" (click)="applyOnServicenow()">
            申請状況確認
          </button>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="アップロード" class="label">
      <div class="upload-field">
        <div class="message-title">メッセージ</div>
        <div class="upload-control-filed">
          <app-message-field #messageField class="message-field"></app-message-field>
          <app-upload-file class="file-drop-field" [acceptExtension]="allowedExtension"
            (fileEvent)="setRequestFile($event)">
          </app-upload-file>
        </div>
        <div class="upload-button">
          <button mat-raised-button (click)="uploadRequestFile()">
            <i class="fa fa-upload"> アップロード</i>
          </button>
        </div>
      </div>
    </mat-tab>

  </mat-tab-group>
</div>

<!-- 非表示ダウンロードリンク -->
<a #downloadLinkRef style="display: none">非表示リンク</a>

<div class="main px-3 py-3">

  <div class="page-title-bg">
  </div>
  <div class="page-title">
    <div class="title-container">カレンダ設定</div>
  </div>

  <mat-tab-group class="page-body mat-tab-fill-height" animationDuration="0ms">
    <mat-tab label="参照/ダウンロード" class="label">
      <div class="tab-body">

        <!-- Tableau表示欄 -->
          <app-tableau-embed class="looker-embed"></app-tableau-embed>
          <div class="download-button-field">
            <app-download-button></app-download-button>
          </div>
      </div>

    </mat-tab>
    <mat-tab label="アップロード" class="label">
      <!-- アップロード -->
      <div class="upload-field">
        <div class="message-title">メッセージ</div>
        <div class="upload-control-filed">
          <app-message-field #messageField class="message-field"></app-message-field>
          <app-upload-file class="file-drop-field" (fileEvent)="setCalendarSettingFile($event)"
            [acceptExtension]="allowedExtension"></app-upload-file>
        </div>
        <div class="upload-button">
          <button mat-raised-button (click)="uploadCalendarSettingFile()">
            <i class="fa fa-upload"> アップロード</i>
          </button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="main-container">
  <h4>権限を選択してください</h4>
  <mat-form-field appearance="fill" style="width: 20rem;">
    <mat-select [(ngModel)]="authority">
      <mat-option *ngFor="let item of authories" [value]="item.value">
        {{item.display}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="d-flex flex-row justify-content-around" style="width: 20rem;">
    <button mat-raised-button (click)="clearAuthority()">クリア</button>
    <button mat-raised-button color="primary" (click)="setAuthority()">権限設定</button>
  </div>
</div>

import { InidividualAuthorityService } from './inidividual-authority.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ScopeViewModel } from 'src/app/services/authority/authority.service.viewmodel';
import { AuthorityService } from 'src/app/services/authority/authority.service';

@Component({
  selector: 'app-individual-authority',
  templateUrl: './individual-authority.component.html',
  styleUrls: ['./individual-authority.component.scss']
})
export class IndividualAuthorityComponent implements OnInit {
  @ViewChild('downloadLinkRef') public downloadLinkRef: ElementRef;
  public organization: string;
  /** true:ダウンロード中 */
  public downloading = false;
  /** 権限スコープ */
  public scopes: ScopeViewModel;

  // SNOW個別権限申請ページプロパティ設定用value
  public chainManager = '1';
  public posManager = '2';
  public businessAdmin = '3';
  public businessAgent = '4';
  public chainAdmin = '5';


  constructor(
    private inidividualAuthorityService: InidividualAuthorityService,
    private authorityService: AuthorityService
  ) { }

  ngOnInit(): void {
    this.organization = environment.organization;
    this.getScopes();
  }

  private async getScopes() {
    this.scopes = await this.authorityService.scopes();
  }

  public downloadChainManager() {
    this.inidividualAuthorityService.downloadChainManager(environment.organization, 'チェーン担当', this.downloadLinkRef);
  }

  public downLoadPosManager() {
    this.inidividualAuthorityService.downloadPosManager(environment.organization, '地区POS担当', this.downloadLinkRef);
  }

  public downLoadChainAdmin() {
    this.inidividualAuthorityService.downloadChainAdmin(environment.organization, 'チェーン本部担当', this.downloadLinkRef);
  }

  public downLoadBusinessAdmin() {
    this.inidividualAuthorityService.downloadBusinessAdmin(environment.organization, '主管部', this.downloadLinkRef);
  }

  public downLoadBusinessAgent() {
    this.inidividualAuthorityService.downloadBusinessAgent(environment.organization, 'AIC業務委託', this.downloadLinkRef);
  }

  public openServiceNow(value: string) {
    window.open(environment.servicenow.individualAuthorityURL + `#${value}`, '_blank');
  }

  public openGeneralRequest() {
    window.open(environment.servicenow.generalURL, '_blank');
  }

  public openHistory() {
    window.open(environment.servicenow.myRequestsURL);
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ElementRef, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileDownloadService } from 'src/app/services/file-download/file-download.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InidividualAuthorityService {
  constructor(
    private http: HttpClient,
    private dl: FileDownloadService
  ) { }

  /** 参照権限一覧ダウンロード */
  public downloadChainStaff(organization: string, dlname: string, el: ElementRef) {
    this.dl.get('api/individual-authority/chain-staff', { 'organization': organization }, dlname, el);
  }

  /** チェーン担当一覧ダウンロード */
  public downloadChainManager(organization: string, dlname: string, el: ElementRef) {
    this.dl.get('api/individual-authority/chain-manager', { 'organization': organization }, dlname, el);
  }

  /** 地区POS担当一覧ダウンロード */
  public downloadPosManager(organization: string, dlname: string, el: ElementRef) {
    this.dl.get('api/individual-authority/pos-manager', { 'organization': organization }, dlname, el);
  }

  /** チェーン本部担当ダウンロード */
  public downloadChainAdmin(organization: string, dlname: string, el: ElementRef) {
    this.dl.get('api/individual-authority/chain-admin', { 'organization': organization }, dlname, el);
  }

  /** 主管部担当ダウンロード */
  public downloadBusinessAdmin(organization: string, dlname: string, el: ElementRef) {
    this.dl.get('api/individual-authority/business-admin', { 'organization': organization }, dlname, el);
  }

  /** 業務委託者ダウンロード */
  public downloadBusinessAgent(organization: string, dlname: string, el: ElementRef) {
    this.dl.get('api/individual-authority/business-agent', { 'organization': organization }, dlname, el);
  }
}

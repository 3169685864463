<div class="main-container">
  <!-- ファイルドロップ欄 -->
  <ngx-file-drop
    dropZoneLabel="Drop files here"
    (onFileDrop)="dropped($event)"
    [accept]="acceptExtension"
  >
    <ng-template
      ngx-file-drop-content-tmp
      let-openFileSelector="openFileSelector"
    >
      <div class="drop-zone" [hidden]="files.length == 1">
        <div class="upload-icon">
          <i class="fas fa-arrow-circle-down"></i>
        </div>
        <div class="upload-text">ここにファイルをドロップ</div>
        <div class="upload-button">
          または
          <button mat-flat-button type="button" (click)="openFileSelector()">
            ファイルを選択
          </button>
        </div>
      </div>
      <!-- 選択ファイル欄 -->
      <div class="file-zone" [hidden]="files.length == 0">
        <h4 class="select-file-message">
          ファイルを選択しました
        </h4>
        <!-- タイトル -->
        <div class="header-row">
            <div class="file-name">選択ファイル名</div>
            <div class="delete">削除</div>
        </div>

        <!-- ファイル名 -->
        <div class="file-row">
            <div *ngFor="let item of files">
              <div class="files" *ngIf="item">
                <div>
                  {{ item.relativePath }}
                </div>
                <div class="garbage">
                  <i
                    class="far fa-trash-alt fa-lg"
                    (click)="deleteFile(item.relativePath)"
                  ></i>
                </div>
              </div>
            </div>
        </div>
      </div>
    </ng-template>
  </ngx-file-drop>
</div>

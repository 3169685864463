import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppAuthService } from 'src/app/services/auth/app-auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss']
})
export class RootComponent implements OnInit {

  constructor(
    private router: Router,
    private auth: AppAuthService
    ) { }

  ngOnInit(): void {
    this.auth.loginStatus().subscribe(res=>{
      if (res) {
        this.router.navigate(['pages/home']);
      }
    });
  }
}

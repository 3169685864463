import { AutoAuthorizationSettingService } from '../auto-authorization-setting.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { MessageFieldComponent } from 'src/app/shared/message-field/message-field.component';
import { UploadResultModel } from 'src/app/shared/message-field/message-field.viewmodel';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-auto-authorization-setting-asd',
  templateUrl: './auto-authorization-setting-asd.component.html',
  styleUrls: ['./auto-authorization-setting-asd.component.scss']
})
export class AutoAuthorizationSettingAsdComponent implements OnInit {
  /** メッセージ欄 */
  @ViewChild('messageField') private messageField: MessageFieldComponent;
  /* ダウンロードリンク */
  @ViewChild('downloadLinkRef') public downloadLinkRef: ElementRef;
  /** 組織別参照権限自動付与ファイルエントリー */
  private fileOrgConfig: NgxFileDropEntry;

  constructor(
    private toastr: ToastrService,
    private autoAuthorizationSettingService: AutoAuthorizationSettingService) { }

  ngOnInit(): void {
  }

  /**
   * 組織別参照権限自動付与ファイルダウンロード
   */
  public downloadConfigFile() {
    this.autoAuthorizationSettingService.downloadConfigFile('組織別参照権限自動付与ファイル', this.downloadLinkRef);
  }

  /**
   * 組織別参照権限自動付与ファイルの選択イベント
   * @param file 選択ファイル
   */
  public selectedOrganizationConfig(file: NgxFileDropEntry) {
    this.fileOrgConfig = file;
  }

  /** 組織設定のアップロードボタン押下イベント */
  public async uploadOrganization() {
    if (!this.fileOrgConfig) {
      this.toastr.error('組織別参照権限自動付与ファイルを選択してください。');
      return;
    }

    const file = await this.getFileFromFileDropEntry(this.fileOrgConfig);
    const data: FormData = new FormData();
    data.append('file', file, this.fileOrgConfig.relativePath);
    data.append('organization', environment.organization);

    this.autoAuthorizationSettingService.uploadOrganizationConfig<UploadResultModel>(data).subscribe(ok => {
      // メッセージ設定
      this.messageField.pushMessage(ok);

      if (ok.errorCount > 0) {
        this.toastr.error('組織別参照権限自動付与ファイルのアップロードに失敗しました。');
      } else {
        this.toastr.info('組織別参照権限自動付与ファイルのアップロードに成功しました。');
      }

      //  結果ファイルのダウンロード
      this.autoAuthorizationSettingService.downloadAuthorityConfigResult(ok.outputFileName, '組織別参照権限自動付与_結果', this.downloadLinkRef);
    });
  }

  /**
   * ファイルドロップから、ファイルを取得します
   * @param file ファイルドロップ
   * @returns ファイル
   */
  private getFileFromFileDropEntry(file: NgxFileDropEntry): Promise<File> {
    return new Promise(resolve => {
      const fileEntry = file.fileEntry as FileSystemFileEntry;
      fileEntry.file((f: File) => {
        resolve(f);
      });
    });
  }
}

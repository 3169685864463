import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CalendarUpdatingService {
  /**
   * コンストラクタ
   */
  constructor(private http: HttpClient) { }

  /**
   * カレンダ更新ファイル取り込みAPI呼び出し
   * @param formData フォームデータ
   */
   public exportCalendarUpdating(formData: FormData): Observable<any> {
    let httpOptions: any = {
        // ヘッダ情報
        headers: new HttpHeaders({
          'contet-type': 'multipart/form-data',
        })
      };
    return this.http.post(environment.apiEndPoint + 'api/calendar-updating/import-file', formData, httpOptions) as Observable<any>;
  }
}

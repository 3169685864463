<div class="upload-field">
  <div class="message-title">メッセージ</div>
  <div class="upload-control-filed">
    <app-message-field #messageField class="message-field"></app-message-field>
    <app-upload-file class="file-drop-field" [acceptExtension]="allowedExtension"
      (fileEvent)="setStoresFile($event)">
    </app-upload-file>
  </div>
  <div class="upload-button-field">
    <button mat-raised-button color="accent" class="apply-button" (click)="uploadStoresFile()">
      <i class="fa fa-upload"> アップロード</i>
    </button>
  </div>
</div>

<!-- 非表示ダウンロードリンク -->
<a #downloadLinkRef style="display: none">非表示リンク</a>

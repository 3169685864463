import { Component, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-confirm-dialog',
  templateUrl: './auth-confirm-dialog.component.html',
  styleUrls: ['./auth-confirm-dialog.component.scss']
})
export class AuthConfirmDialogComponent implements OnInit {

  public pressedOk: EventEmitter<any> = new EventEmitter<any>();

  constructor(
  ) {
  }

  ngOnInit(): void {
  }

  public clickedOK() {
    this.pressedOk.emit();
  }
}

<div class="contents-container">
  <div class=" pt-3 pb-3">
    人事異動時などで、申請で付与された参照権限を一括で変更するためのファイルをダウンロードできます。<br>
    ダウンロードファイルを開き、権限変更したい箇所を変更してください。
  </div>
  <div class="py-3 download-field">
    <div>
      ファイルダウンロード：
    </div>
    <div style="margin-left: 0.5rem;">
      <button mat-raised-button color="primary" (click)="downloadDirectConfigFile()">ダウンロード</button>
    </div>
  </div>
</div>

<a #downloadLinkRef style="display: none">非表示リンク</a>

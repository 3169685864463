<div class="main px-3 py-3">
  <div class="page-title-bg">
  </div>
  <div class="page-title">
    <div class="title-container">マスタ権限設定</div>
  </div>
  <mat-tab-group class="page-body mat-tab-fill-height" animationDuration="0ms">
    <mat-tab label="参照" class="label">
      <div class="tab-body">

        <!-- Tableau表示欄 -->
        <app-tableau-embed class="looker-embed"></app-tableau-embed>
        <div class="download-button-field">
          <app-download-button></app-download-button>
        </div>

      </div>
    </mat-tab>
    <mat-tab label="設定履歴" class="label">
      <!-- 設定履歴 -->
      <div class="history-field">
        <div id="I-set-maintenance-controls-history" class="looker-embed"></div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="main px-3 py-3">
  <div class="page-title-bg">
  </div>
  <div class="page-title">
    <div class="title-container">新商品登録</div>
  </div>

  <mat-tab-group class="page-body mat-tab-fill-height" animationDuration="0ms">
    <mat-tab label="ダウンロード" class="label">
      <div class="tab-body">
        <div class="heading">新商品リストをダウンロードする</div>
        <div class="ps-4 mt-3">
          <div class="spinner" *ngIf="downloading">
            <mat-spinner [diameter]="35"></mat-spinner>
          </div>
          <button [disabled]="downloading" mat-raised-button color="primary" (click)="clickedDownLoad()">
            <i class="fa fa-download"></i>
            ダウンロード
          </button>
        </div>
      </div>
    </mat-tab>
    <mat-tab *ngIf="scopes?.showTabUploadNewGoods" label="アップロード" class="label">
      <!-- アップロード -->
      <div class="upload-field">
        <div class="message-title">メッセージ</div>
        <div class="upload-control-filed">
          <app-message-field class="message-field"></app-message-field>
          <app-upload-file class="file-drop-field" (fileEvent)="setFile($event)"></app-upload-file>
        </div>
        <div class="upload-button">
          <button mat-raised-button (click)="uploadFile()">
            <i class="fa fa-upload"> アップロード</i>
          </button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<!-- 非表示ダウンロードリンク -->
<a #downloadLinkRef style="display: none">非表示リンク</a>

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { AuthorityService } from 'src/app/services/authority/authority.service';
import { ScopeViewModel } from 'src/app/services/authority/authority.service.viewmodel';
import { MaintenanceMenuService } from 'src/app/shared/maintenance-menu/maintenance-menu.service';
import { MessageFieldComponent } from 'src/app/shared/message-field/message-field.component';
import { UploadResultModel } from 'src/app/shared/message-field/message-field.viewmodel';
import { environment } from 'src/environments/environment';
import { environment_org } from 'src/environments/environment_organization';
import { UpdateStoresService } from '../../store-master/update-stores.service';

@Component({
  selector: 'app-upload-unmatched-store',
  templateUrl: './upload-unmatched-store.component.html',
  styleUrls: ['./upload-unmatched-store.component.scss']
})
export class UploadUnmatchedStoreComponent implements OnInit {
  /* 店舗情報ファイル */
  private storesFile: NgxFileDropEntry;
  /* 拡張子 */
  public allowedExtension = '.xlsx';
  /* メッセージフィールド */
  @ViewChild('messageField') private messageField: MessageFieldComponent;
  /** ダウンロードリンク */
  @ViewChild('downloadLinkRef') public downloadLinkRef: ElementRef;
  /** 組織情報 */
  public org = '';
  /** 権限スコープ */
  public scopes: ScopeViewModel;


  /**
   * コンストラクタ
   */
  constructor(
    public maintenanceMenuService: MaintenanceMenuService,
    private toastr: ToastrService,
    private updateStoresService: UpdateStoresService,
    public authorityService: AuthorityService
  ) { }

  /**
   * 初期化処理
   */
  ngOnInit(): void {
    this.org = environment_org.organization;
    this.getScopes();
  }

  private async getScopes() {
    this.scopes = await this.authorityService.scopes();
  }

  /**
   * 店舗情報ファイル取得
   */
  public setStoresFile(file: NgxFileDropEntry) {
    // エクセルファイル未選択
    if (file !== null && !file.relativePath.endsWith(this.allowedExtension)) {
      this.toastr.error('Excelファイル以外が選択されています。ファイルを再選択してください', 'Excelファイル非選択');
      return;
    }
    this.storesFile = file;
  }

  /**
   * アンマッチ店舗ファイルアップロード処理
   */
  public uploadStoresFile() {
    // ファイル未選択
    if (!this.storesFile || !this.storesFile.fileEntry.isFile) {
      this.toastr.error('アンマッチ店舗ファイルが選択されていません。', 'アンマッチ店舗ファイル未選択');
      return;
    }

    // アップロード完了イベント
    const uploaded = (res: UploadResultModel) => {
      this.messageField.pushMessage(res);
      // エラーあり
      if (res.errorCount > 0) {
        this.toastr.error('アンマッチ店舗ファイルのアップロードに失敗しました', 'アップロード失敗');
      }
      // 警告あり
      else if (res.warningCount > 0) {
        this.toastr.warning('アンマッチ店舗ファイルのアップロードで警告があります', 'アップロード警告');
      }
      // 正常終了
      else {
        this.toastr.info('アンマッチ店舗ファイルのアップロードが完了しました', 'アップロード完了');
      }

      // 結果ファイルのダウンロード
      if (res.outputFileName) {
        this.updateStoresService.downloadResultFile(res.outputFileName, 'アンマッチ店舗変更ファイル', this.downloadLinkRef);
      }
    };

    const formData = new FormData();
    formData.append('organization', environment.organization);
    const fileEntry = this.storesFile.fileEntry as FileSystemFileEntry;
    const read = (file: File) => {
      formData.append('file', file, this.storesFile.relativePath);

      // アンマッチアップロード
      this.updateStoresService.uploadStoreMasterUnmatched(formData).subscribe(uploaded);
    };

    fileEntry.file(read);
  }
}

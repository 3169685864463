import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AutoAuthorizationSettingService } from '../auto-authorization-setting.service';

@Component({
  selector: 'app-direct-authorization-setting-ab',
  templateUrl: './direct-authorization-setting-ab.component.html',
  styleUrls: ['./direct-authorization-setting-ab.component.scss']
})
export class DirectAuthorizationSettingAbComponent implements OnInit {
  /* ダウンロードリンク */
  @ViewChild('downloadLinkRef') public downloadLinkRef: ElementRef;

  constructor(private toastr: ToastrService,
    private autoAuthorizationSettingService: AutoAuthorizationSettingService) { }

  ngOnInit(): void {
  }

  /** 手動設定のダウンロード */
  public downloadDirectConfigFile() {
    this.autoAuthorizationSettingService.downloadDirectConfigFile('参照権限手動一括付与設定ファイル', this.downloadLinkRef);
  }
}

import { environment_org } from './../../../environments/environment_organization';
import { MenuItemsViewModel } from './maintenance-menu-viewmodel';
import { ScopeViewModel } from './../../services/authority/authority.service.viewmodel';
import { AuthorityService } from './../../services/authority/authority.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { RouterService } from 'src/app/services/router/router.service';
import { MaintenanceMenuService } from './maintenance-menu.service';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-maintenance-menu',
  templateUrl: './maintenance-menu.component.html',
  styleUrls: ['./maintenance-menu.component.scss']
})
export class MaintenanceMenuComponent implements OnInit {
  @ViewChild('downloadLinkRef') public downloadLinkRef: ElementRef;
  public menuItems: MenuItemsViewModel[] = [];
  public scopes: ScopeViewModel;
  constructor(
    public routerService: RouterService,
    public maintenanceMenuService: MaintenanceMenuService,
    private authorityService: AuthorityService,
  ) { }

  ngOnInit(): void {
    this.createMenu();
  }

  /**
   * メニュー内項目を生成する
   */
  private async createMenu() {
    this.scopes = await this.authorityService.scopes();
    this.menuItems = [
      {
        title: '申請',
        options:
          [
            {
              title: '手渡しPOS申請',
              path: '',
              initSelected: false,
              func: () => { this.openPOSApply(); },
              visible: this.scopes.showMenuDirectRequest
            },
            {
              title: 'データリカバリ申請',
              path: '',
              func: () => { this.openRecoveryApply(); },
              initSelected: false,
              visible: this.scopes.showMenuRecoveryRequest
            },
            {
              title: 'チェーン追加改修申請',
              path: 'pages/request-for-chain',
              func: () => { this.routerService.navigateByUrl('pages/request-for-chain'); },
              initSelected: false,
              visible: this.scopes.showMenuRequestForChain
            },
            {
              title: '汎用申請',
              path: '',
              func: () => { this.openGeneralApply(); },
              initSelected: false,
              visible: true
            },
            {
              title: '問い合わせ',
              path: '',
              func: () => { this.openInquiry(); },
              initSelected: false,
              visible: true
            },
          ],
        visible: this.scopes.showMenuRequest
      },
      {
        title: '商品マスタ',
        options:
          [
            {
              title: '新商品登録',
              path: 'pages/register-new-goods',
              func: () => { this.routerService.navigateByUrl('pages/register-new-goods'); },
              initSelected: false,
              visible: this.scopes.showMenuRegisterNewGoods
            },
            {
              title: 'アンマッチダウンロード・申請',
              path: 'pages/solve-unmatched-goods',
              func: () => { this.routerService.navigateByUrl('pages/solve-unmatched-goods'); },
              initSelected: false,
              visible: true
            },
            {
              title: 'マスタダウンロード・新規変更申請',
              path: 'pages/register-goods',
              func: () => { this.routerService.navigateByUrl('pages/register-goods'); },
              initSelected: false,
              visible: true
            },
            {
              title: '変換表ダウンロード・申請',
              path: 'pages/convert-goods-attribute',
              func: () => { this.routerService.navigateByUrl('pages/convert-goods-attribute'); },
              initSelected: false,
              visible: this.scopes.showMenuConvertGoodsAttribute
            }
          ],
        visible: this.scopes.showMenuGoodsMaster
      },
      {
        title: '店舗マスタ',
        options:
          [
            {
              title: 'アンマッチダウンロード・申請',
              path: 'pages/store-unmatched',
              func: () => { this.routerService.navigateByUrl('pages/store-unmatched'); },
              initSelected: false,
              visible: true
            },
            {
              title: 'マスタダウンロード・新規変更申請',
              path: 'pages/store-master',
              func: () => { this.routerService.navigateByUrl('pages/store-master'); },
              initSelected: false,
              visible: true
            }
          ],
        visible: this.scopes.showMenuStoreMaster
      },
      {
        title: '権限マスタ',
        options:
          [
            {
              title: 'チェーン参照権限申請',
              path: 'pages/set-reference-controls',
              func: () => { this.routerService.navigateByUrl('pages/set-reference-controls'); },
              initSelected: false,
              visible: true
            },
            {
              title: '個別権限申請',
              path: 'pages/individual-authority',
              func: () => { this.routerService.navigateByUrl('pages/individual-authority'); },
              initSelected: false,
              visible: true
            },
          ],
        visible: this.scopes.showMenuAuthorityMaster
      },
      {
        title: 'デバッグメニュー',
        options:
          [
            {
              title: '権限選択',
              path: 'pages/update-stores',
              func: () => { this.routerService.navigateByUrl('pages/authority-selector'); },
              initSelected: false,
              visible: true
            }
          ],
        visible: environment.debugMenu
      },
    ];

    const path = this.routerService.previousPage;
    path.subscribe(x => {
      this.menuItems.forEach(item => {
        const menu = item.options.find(m => m.path === x);
        if (!menu) {
          return;
        }
        menu.initSelected = true;
      });
    });
  }

  /** 汎用申請 */
  private openGeneralApply() {
    window.open(environment.servicenow.generalURL, '_blank');
  }

  /** POS手渡し依頼 */
  private openPOSApply() {
    window.open(environment.servicenow.POSRequestURL, '_blank');
  }

  /** データリカバリ依頼 */
  private openRecoveryApply() {
    window.open(environment.servicenow.recoveryRequestURL, '_blank');
  }

  /** データリカバリ依頼 */
  private openInquiry() {
    window.open(environment.servicenow.inquiryURL, '_blank');
  }

  public closeMenu() {
    this.maintenanceMenuService.closeMenu();
  }
}

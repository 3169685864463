import { RequestForChainService } from './request-for-chain.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { MaintenanceMenuService } from 'src/app/shared/maintenance-menu/maintenance-menu.service';
import { MessageFieldComponent } from 'src/app/shared/message-field/message-field.component';
import { UploadResultModel } from 'src/app/shared/message-field/message-field.viewmodel';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-request-for-chain',
  templateUrl: './request-for-chain.component.html',
  styleUrls: ['./request-for-chain.component.scss']
})
export class RequestForChainComponent implements OnInit {
  @ViewChild('downloadLinkRef') public downloadLinkRef: ElementRef;
  /* メッセージフィールド */
  @ViewChild('messageField') private messageField: MessageFieldComponent;
  /* 店舗情報ファイル */
  private requestFile: NgxFileDropEntry;
  /* 拡張子 */
  public allowedExtension = '.xlsx';

  /**
   * コンストラクタ
   */
  constructor(
    public maintenanceMenuService: MaintenanceMenuService,
    public requestForChainService: RequestForChainService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {

  }
  /**
   * チェーン追加改修申請ファイルをダウンロードします
   * @param fileName ファイル名（ファイルパス）
   */
   public downloadRequestFile() {
    this.requestForChainService.downloadRequestFile('チェーン追加改修申請書', this.downloadLinkRef);
  }

  /**
   * 申請ファイル取得
   */
  public setRequestFile(file: NgxFileDropEntry) {
    // エクセルファイル未選択
    if (file !== null && !file.relativePath.endsWith(this.allowedExtension)) {
      this.toastr.error('Excelファイル以外が選択されています。ファイルを再選択してください', 'Excelファイル非選択');
      return;
    }
    this.requestFile = file;
  }

  /**
   * ServiceNowの申請ページを開く
   */
  public applyOnServicenow() {
    window.open(environment.servicenow.requestForChainURL, '_blank');
  }

  public applyCheck() {
    window.open(environment.servicenow.myRequestsURL, '_blank');
  }
}

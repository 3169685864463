import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { ElementRef, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileDownloadService } from 'src/app/services/file-download/file-download.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegisterNewGoodsService {
  private httpOptions = {
    headers: new HttpHeaders({
      'contet-type': 'multipart/form-data',
    })
  };

  constructor(
    private http: HttpClient,
    private dl: FileDownloadService
  ) { }

  public uploadNewGoodsList(file: FormData) {
    return this.http.post(environment.apiEndPoint + 'api/register-new-goods/upload-list', file, this.httpOptions) as Observable<any>;
  }

  public downloadIntageGoodsList(organization: string, dlname: string, el: ElementRef) {
    this.dl.get('api/register-new-goods/download-intage-list', { 'organization': organization }, dlname, el);
  }

  public downloadGoodsList(fileName: string, dlname: string, el: ElementRef) {
    this.dl.get('api/register-new-goods/download-list', { 'fileName': fileName }, dlname, el);
  }
}

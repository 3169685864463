import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgxFileDropEntry } from 'ngx-file-drop';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent implements OnInit {
  @Input() files: NgxFileDropEntry[] = [];
  @Input() acceptExtension: string;
  @Output() fileEvent = new EventEmitter<NgxFileDropEntry>();

  ngOnInit(): void {}

  /**
   * ファイルアップロード処理
   */
  public dropped(files: NgxFileDropEntry[]) {
    if(!files){
      return;
    }

    if (files[0].fileEntry.isFile){
      this.files = files;
      this.fileEvent.emit(files[0]);
    }
  }

  /**
   * ファイル削除処理
   */
  public deleteFile(path: string) {
    if (this.files.length > 0) {
      this.files = this.files.filter(x => x.relativePath !== path);
      this.fileEvent.emit(null);
    }
  }
}

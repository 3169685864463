import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { MaintenanceMenuService } from 'src/app/shared/maintenance-menu/maintenance-menu.service';
import { MessageFieldComponent } from 'src/app/shared/message-field/message-field.component';
import { UploadResultModel } from 'src/app/shared/message-field/message-field.viewmodel';
import { environment } from 'src/environments/environment';
import { UpdateStoresService } from '../update-stores.service';

@Component({
  selector: 'app-upload-store-master',
  templateUrl: './upload-store-master.component.html',
  styleUrls: ['./upload-store-master.component.scss']
})
export class UploadStoreMasterComponent implements OnInit {
  /* 店舗情報ファイル */
  private storesFile: NgxFileDropEntry;
  /* 拡張子 */
  public allowedExtension = '.xlsx';
  /* メッセージフィールド */
  @ViewChild('messageField') private messageField: MessageFieldComponent;
  /** ダウンロードリンク */
  @ViewChild('downloadLinkRef') public downloadLinkRef: ElementRef;

  constructor(
    public maintenanceMenuService: MaintenanceMenuService,
    private toastr: ToastrService,
    private updateStoresService: UpdateStoresService,
  ) { }

  ngOnInit(): void {
  }

  /**
   * 店舗情報ファイル取得
   */
  public setStoresFile(file: NgxFileDropEntry) {
    // エクセルファイル未選択
    if (file !== null && !file.relativePath.endsWith(this.allowedExtension)) {
      this.toastr.error('Excelファイル以外が選択されています。ファイルを再選択してください', 'Excelファイル非選択');
      return;
    }
    this.storesFile = file;
  }

  /**
   * 店舗情報ファイルアップロード処理
   */
  public uploadStoresFile() {
    // ファイル未選択
    if (!this.storesFile || !this.storesFile.fileEntry.isFile) {
      this.toastr.error('店舗情報ファイルが選択されていません。', '店舗情報ファイル未選択');
      return;
    }

    // アップロード完了イベント
    const uploaded = (res: UploadResultModel) => {
      this.messageField.pushMessage(res);
      // エラーあり
      if (res.errorCount > 0) {
        this.toastr.error('店舗情報のアップロードに失敗しました', 'アップロード失敗');
      }
      // 警告あり
      else if (res.warningCount > 0) {
        this.toastr.warning('店舗情報のアップロードで警告があります', 'アップロード警告');
      }
      // 正常終了
      else {
        this.toastr.info('店舗情報のアップロードが完了しました', 'アップロード完了');
      }

      // 結果ファイルのダウンロード
      if (res.outputFileName) {
        this.updateStoresService.downloadResultFile(res.outputFileName, '店舗マスタ変更ファイル', this.downloadLinkRef);
      }
    };

    // ファイル読み込み設定
    const formData = new FormData();
    formData.append('organization', environment.organization);
    const fileEntry = this.storesFile.fileEntry as FileSystemFileEntry;
    const read = (file: File) => {
      formData.append('file', file, this.storesFile.relativePath);

      // 店舗情報ファイル取り込みAPI呼び出し
      this.updateStoresService.uploadStoreMaster(formData).subscribe(uploaded);
    };

    // 実際のファイル読み込み
    fileEntry.file(read);
  }

}

import { ElementRef, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { FileDownloadService } from 'src/app/services/file-download/file-download.service';

@Injectable({
  providedIn: 'root'
})
export class ConvertGoodsAttributeService {
  /**
   * コンストラクタ
   */
  constructor(
    private http: HttpClient,
    private dl: FileDownloadService) { }

  /**
   * チェーンリスト取得API呼び出し
   */
   public initialize(organization: string, userId: string): Observable<any> {
    const httpOptions: any = {
      params: { 'organization': organization, 'userId': userId }
    };
    return this.http.get(environment.apiEndPoint + 'api/convert-goods/initialize', httpOptions) as Observable<any>;
  }

  /**
   * 変換表ファイル検証API呼び出し
   * @param formData フォームデータ
   */
  public testConvertGoodsAttribute(formData: FormData): Observable<any> {
    return this.http.post(environment.apiEndPoint + 'api/convert-goods/test', formData) as Observable<any>;
  }

  /**
   * 変換結果ファイル取得API呼び出し
   */
  public getTestResultFile(outputFileName:string, dlname: string, el: ElementRef) {
    this.dl.get('api/convert-goods/get-test-result-file', { 'outputFileName': outputFileName }, dlname, el);
  }

  /**
   * 変換表ファイル本番適応API呼び出し
   * @param formData フォームデータ
   */
  public applyConvertGoodsAttribute(formData: FormData): Observable<any> {
    return this.http.post(environment.apiEndPoint + 'api/convert-goods/apply', formData) as Observable<any>;
  }

  /**
   * 変換定義ルールファイル取得API呼び出し
   */
  public getDefineFile(dlname: string, el: ElementRef, isChain: string, organization: string, chainCd: string = null) {
    this.dl.get('api/convert-goods/get-define-file', {
        'isChain': isChain,
        'chainCd': chainCd,
        'organization': organization
      }, dlname, el);
  }
}

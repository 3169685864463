import { ScopeViewModel } from './../../services/authority/authority.service.viewmodel';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MaintenanceMenuService } from 'src/app/shared/maintenance-menu/maintenance-menu.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ConvertGoodsAttributeService } from 'src/app/pages/convert-goods-attribute/convert-goods-attribute.service';
import { RouterService } from 'src/app/services/router/router.service';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';
import { UploadResultModel } from 'src/app/shared/message-field/message-field.viewmodel';
import { MessageFieldComponent } from 'src/app/shared/message-field/message-field.component';
import { AppAuthService } from 'src/app/services/auth/app-auth.service';
import { environment } from 'src/environments/environment';
import { AuthorityService } from 'src/app/services/authority/authority.service';
import { HttpResponse } from '@angular/common/http';

type ConvertGoodsFileType = 'define' | 'rule' | 'test';

@Component({
  selector: 'app-convert-goods-attribute',
  templateUrl: './convert-goods-attribute.component.html',
  styleUrls: ['./convert-goods-attribute.component.scss']
})

export class ConvertGoodsAttributeComponent implements OnInit {
  /* メッセージフィールド */
  @ViewChild('messageField') private messageField: MessageFieldComponent;
  /* ダウンロードリンク */
  @ViewChild('downloadLinkRef') public downloadLinkRef: ElementRef;

  /* チェーン選択オートコンプリートフォーム */
  public chainListControl = new FormControl();
  /* チェーンフラグ */
  public isChain = false;
  /* チェーンリスト */
  public chainList = [];
  /* フィルタリングされたチェーンリスト */
  public filteredChainList: Observable<{}>;
  /* 選択チェーン */
  public selectedChainName = '';
  /* 変換ルールファイル選択表示/非表示フラグ */
  public isSelectConvertFile = false;
  /* 拡張子 */
  public allowedExtension = '.xlsx';
  /* テスト実行フラグ */
  public isTest = false;
  /* 属性変換テスト実行フラグ */
  public testConvert = false;
  /* 属性定義ファイル */
  public defineFile: NgxFileDropEntry;
  /* 変換ルールファイル */
  public ruleFile: NgxFileDropEntry;
  /* 商品マスタファイル */
  public testFile: NgxFileDropEntry;
  /* 属性定義ファイル状態 */
  public define = 'new';
  /* 変換ルールファイル状態 */
  public rule = 'new';
  /* 商品マスタ状態 */
  public test = 'new';
  /* 権限スコープ */
  public scopes: ScopeViewModel;

  /**
   * コンストラクタ
   */
  constructor(
    public maintenanceMenuService: MaintenanceMenuService,
    private toastr: ToastrService,
    private convertGoodsAttributeService: ConvertGoodsAttributeService,
    private routerService: RouterService,
    private appAuthService: AppAuthService,
    private authorityService: AuthorityService) { }

  /**
   * 初期化処理
   */
  ngOnInit(): void {
    this.getScopes();
    this.filteredChainList = this.chainListControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this.autoCcompleteFilter(value))
      );
    this.routerService.setPreviousPage();
    this.initialize();
  }

  private async initialize() {
    const userId = await this.authorityService.getUserId();
    this.convertGoodsAttributeService.initialize(environment.organization, userId).subscribe((res) => {
      this.chainList = res.chainList;
    });
  }

  private async getScopes() {
    this.scopes = await this.authorityService.scopes();
  }

  /**
   * オートコンプリートフィルタリング
   * @param filteringStr フィルタリングする文字列
   */
  private autoCcompleteFilter(filteringStr: any) {
    let filteredKeys = '';
    filteredKeys = filteringStr.toLowerCase();
    const filtered = this.chainList.filter(it => it.chainName.toLowerCase().includes(filteredKeys));
    return filtered;
  }

  /**
   * オートコンプリート表示処理
   */
  public autoCcompleteDisplay(value: any) {
    if (!value) {
      return '';
    }
    const chain = this.chainList.find(x => x.chainCd === value);
    return `${chain.chainCd} ${chain.chainName}`;
  }

  /**
   * 変換表ファイル取得
   */
  private getConvetAttributeFile(file: NgxFileDropEntry): Promise<File> {
    return new Promise(resolve => {
      const fileEntry = file.fileEntry as FileSystemFileEntry;
      fileEntry.file((f: File) => {
        resolve(f);
      });
    });
  }

  /**
   * 変換表ファイル検証
   */
  public async testConvertGoodsAttributeFile() {
    // ファイル未選択
    if (!this.defineFile && this.define === 'new') {
      this.toastr.error('新規属性定義ファイルが選択されていません。', '属性定義ファイル未選択');
      return;
    }

    if (!this.ruleFile && this.rule === 'new') {
      this.toastr.error('新規変換ルールファイルが選択されていません。', '変換ルールファイル未選択');
      return;
    }

    if (!this.testFile && this.test === 'new') {
      this.toastr.error('サンプルファイルが選択されていません。', 'サンプルファイル未選択');
      return;
    }

    if (this.isChain && !this.chainListControl.value) {
      this.toastr.error('チェーン名が選択されていません。', 'チェーン名未選択');
      return;
    }

    const chainObj = this.chainList.find(x => x.chainCd === this.chainListControl.value);
    if (this.isChain && !chainObj) {
      this.toastr.error('選択チェーン名が異常です。', 'チェーン名異常');
      return;
    }

    const formData = new FormData();
    if (this.define === 'new') {
      const file = await this.getConvetAttributeFile(this.defineFile);
      formData.append('defineFile', file, this.defineFile.relativePath);
    }
    else {
      formData.append('defineFile', null);
    }

    if (this.rule === 'new') {
      const file = await this.getConvetAttributeFile(this.ruleFile);
      formData.append('ruleFile', file, this.ruleFile.relativePath);
    }
    else {
      formData.append('ruleFile', null);
    }

    if (this.test === 'new') {
      const file = await this.getConvetAttributeFile(this.testFile);
      formData.append('testFile', file, this.testFile.relativePath);
    }
    else {
      formData.append('testFile', null);
    }

    formData.append('isChain', String(this.isChain));
    let chainCd = '';
    let chainName = '';
    if (this.isChain) {
      chainCd = this.chainListControl.value;
      chainName = chainObj.chainName;
      formData.append('chainCd', chainCd);
    }
    formData.append('organization', environment.organization);
    formData.append('userId', await this.authorityService.getUserId());

    // 変換表ファイルテストAPI呼び出し
    this.convertGoodsAttributeService.testConvertGoodsAttribute(formData).subscribe((res: UploadResultModel) => {
      this.messageField.pushMessage(res);
      if (res.errorCount > 0) {
        this.toastr.error('変換表のテストに失敗しました', 'テスト失敗');
      } else if (res.warningCount > 0) {
        this.toastr.warning('変換表のテストで警告があります', 'テスト警告');
      } else {
        this.toastr.info('変換表のテストが完了しました', 'テスト完了');
      }

      if (res.outputFileName) {
        // 変換結果のダウンロード
        const name = '変換結果ファイル_' + (!this.isChain) ? '全チェーン共通' : chainName;
        this.convertGoodsAttributeService.getTestResultFile(res.outputFileName, name, this.downloadLinkRef);
      }
    });
  }

  /**
   * 変換表ファイル本番適応
   */
  public async applyConvertGoodsAttributeFile() {
    // ファイル未選択
    if (!this.defineFile && this.define === 'new') {
      this.toastr.error('新規属性定義ファイルが選択されていません。', '属性定義ファイル未選択');
      return;
    }

    if (!this.ruleFile && this.rule === 'new') {
      this.toastr.error('新規変換ルールファイルが選択されていません。', '変換ルールファイル未選択');
      return;
    }

    if (this.isChain && !this.chainListControl.value) {
      this.toastr.error('チェーン名が選択されていません。', 'チェーン名未選択');
      return;
    }

    if (this.isChain && !this.chainList.find(x => x.chainCd === this.chainListControl.value)) {
      this.toastr.error('選択チェーン名が異常です。', 'チェーン名異常');
      return;
    }

    const formData = new FormData();
    if (this.define === 'new') {
      const file = await this.getConvetAttributeFile(this.defineFile);
      formData.append('defineFile', file, this.defineFile.relativePath);
    }
    else {
      formData.append('defineFile', null);
    }

    if (this.rule === 'new') {
      const file = await this.getConvetAttributeFile(this.ruleFile);
      formData.append('ruleFile', file, this.ruleFile.relativePath);
    }
    else {
      formData.append('ruleFile', null);
    }

    formData.append('isChain', String(this.isChain));
    if (this.isChain) {
      formData.append('chainCd', this.chainListControl.value);
    }
    formData.append('organization', environment.organization);
    formData.append('userId', await this.authorityService.getUserId());

    // 本番適応API呼び出し
    this.convertGoodsAttributeService.applyConvertGoodsAttribute(formData).subscribe((res: UploadResultModel) => {
      this.messageField.pushMessage(res);
      // エラーあり
      if (res.errorCount > 0) {
        this.toastr.error('変換表の本番適応に失敗しました', '本番適応失敗');
      }
      // 警告あり
      else if (res.warningCount > 0) {
        this.toastr.warning('変換表の本番適応で警告があります', '本番適応警告');
      }
      // 正常終了
      else {
        this.toastr.info('変換表の本番適応が完了しました', '本番適応完了');
      }
    });
  }

  /**
   * 変換表ファイルダウンロードボタン押下処理
   */
  public clickDownloadCovertingAttributeFile() {
    if (this.isChain && !this.chainListControl.value) {
      this.toastr.error('チェーン名が選択されていません。', 'チェーン名未選択');
      return;
    }
    const chainObj = this.chainList.find(x => x.chainCd === this.chainListControl.value);
    if (this.isChain && !chainObj) {
      this.toastr.error('選択チェーン名が異常です。', 'チェーン名異常');
      return;
    }

    let chainCd = '';
    let chainName = '';
    if (this.isChain) {
      chainCd = this.chainListControl.value;
      chainName = chainObj.chainName;
    }

    const name = (!this.isChain) ? '属性定義' : '属性定義_' + chainName;
    this.convertGoodsAttributeService.getDefineFile(name, this.downloadLinkRef, String(this.isChain), environment.organization, chainCd);
  }

  /**
   * 変換表ファイル設定処理
   */
  public setCovertingAttributeFile(file: NgxFileDropEntry, fileType: ConvertGoodsFileType) {
    if (file !== null && !file.relativePath.endsWith(this.allowedExtension)) {
      this.toastr.error('Excelファイル以外が選択されています。ファイルを再選択してください', 'Excelファイル非選択');
      return;
    }

    switch (fileType) {
      case 'define':
        this.defineFile = file;
        break;
      case 'rule':
        this.ruleFile = file;
        break;
      case 'test':
        this.testFile = file;
        break;
    }
  }

  /**
   * ServiceNowの申請ページを開く
   */
  public applyOnServicenow() {
    window.open(environment.servicenow.convertGoodsAttributeURL, '_blank');
  }
}


import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ElementRef, Injectable } from '@angular/core';
import { FileDownloadService } from 'src/app/services/file-download/file-download.service';

@Injectable({
  providedIn: 'root'
})
export class RegisterGoodsService {

  constructor(
    private http: HttpClient,
    private dl: FileDownloadService
  ) { }

  /**
   * 画面初期化API呼び出し
   */
  public initialize(organization: string): Observable<any> {
    const httpOptions: any = {
      params: {
        'organization': organization
      }
    };
    return this.http.get(environment.apiEndPoint + 'api/register-goods/initialize', httpOptions) as Observable<any>;
  }

  public getGoodsMasterCondition(organization: string): Observable<any> {
    const httpOptions: any = {
      params: {
        'organization': organization
      }
    };
    return this.http.get(environment.apiEndPoint + 'api/register-goods/goods-master-condition', httpOptions) as Observable<any>;
  }

  public uploadGoodsList(file: FormData) {
    const httpOptions = {
      headers: new HttpHeaders({
        'contet-type': 'multipart/form-data',
      })
    };
    return this.http.post(environment.apiEndPoint + 'api/register-goods/upload-list', file, httpOptions) as Observable<any>;
  }

  public downloadGoodsList(fileName: string, dlname: string, el: ElementRef) {
    this.dl.get('api/register-goods/download-list', { 'fileName': fileName }, dlname, el);
  }

  public downloadGoodsMaster(param: any, dlname: string, el: ElementRef) {
    this.dl.post('api/register-goods/download-master', param, dlname, el);
  }

  /**
   * 商品マスタテンプレートダウンロードAPI呼び出し
   */
  public downloadGoodsMasterTemplate(param: any, dlname: string, el: ElementRef) {
    this.dl.get('api/register-goods/download-master-template', param, dlname, el);
  }
}

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { ElementRef, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const MimeFileType = [
  { contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', extension: 'xlsx' },
  { contentType: 'application/zip', extension: 'zip' },
  { contentType: 'text/csv', extension: 'csv' },
];

@Injectable({
  providedIn: 'root'
})
export class FileDownloadService {

  constructor(
    private http: HttpClient) { }

  /**
   * GET メソッドでダウンロードファイルを取得します
   * @param path ダウンロード要求先のパス
   * @param params GETパラメータ
   * @param name ブラウザのダウンロードファイル名 (拡張子無し)
   * @param anchor 実際にダウンロードさせるアンカー
   */
  public get(path: string, params: any, name: string, anchor: ElementRef<any>) {
    const options: any = {
      responseType: 'blob',
      observe: 'response',
      params: params
    };

    var resp = this.http.get(environment.apiEndPoint + path, options) as Observable<any> as Observable<HttpResponse<Blob>>
    resp.subscribe(x => this.responseHandler(x, name, anchor));
  }

  /**
   * POST メソッドでダウンロードファイルを取得します
   * @param path ダウンロード要求先のパス
   * @param params POST パラメータ
   * @param name ブラウザのダウンロードファイル名 (拡張子無し)
   * @param anchor 実際にダウンロードさせるアンカー
   */
  public post(path: string, params: any, name: string, anchor: ElementRef<any>) {
    const options: any = {
      responseType: 'blob',
      observe: 'response'
    };

    var resp = this.http.post(environment.apiEndPoint + path, params, options) as Observable<any> as Observable<HttpResponse<Blob>>
    resp.subscribe(x => this.responseHandler(x, name, anchor));
  }

  private responseHandler(resp: HttpResponse<Blob>, name: string, anchor: ElementRef) {
    const el = anchor.nativeElement as HTMLAnchorElement;
    el.download = null;

    const ctype = resp.headers.get('content-type');
    const mime = MimeFileType.find(x => x.contentType === ctype);
    if (mime) {
      el.download = `${name}_${environment.organization}.${mime.extension}`;
    } else {
      el.download = `${environment.organization}_${name}`;
    }

    el.href = URL.createObjectURL(resp.body);
    el.click();
  }
}

<div class="download-button-field">
  <div class="spinner" *ngIf="downloading">
    <mat-spinner [diameter]="35"></mat-spinner>
  </div>
  <!-- ボタン本体 -->
  <button [disabled]="downloading" mat-raised-button color="primary" (click)="clickedDownLoad()">
    <i class="fa fa-download"></i>
    ダウンロード
  </button>
</div>

<!-- 非表示ダウンロードリンク -->
<a #downloadLinkRef style="display: none;">非表示リンク</a>

<div class="main px-3 py-3">
  <div class="page-title-bg">
  </div>
  <div class="page-title">
    <div class="title-container">商品マスタダウンロード・新規変更申請</div>
  </div>

  <mat-tab-group class="page-body mat-tab-fill-height" animationDuration="0ms">

    <mat-tab label="ダウンロード/申請" class="label">

      <div class="tab-body">
        <!-- テンプレートダウンロード -->
        <div class="template-field">
          <div>
            <h5 class="heading">商品登録の申請テンプレートファイルをダウンロードする</h5>
          </div>
          <div class="template-field-chain">
            <mat-checkbox color="primary" [(ngModel)]="templeteChain.include">
              商品グループ(チェーン個別)を含める
            </mat-checkbox>
            <mat-form-field [ngClass]="{ 'prevent-touch': !templeteChain.include }" floatLabel="never" appearance="fill"
              style="width: 40%; margin-left: 5rem;">
              <input type="text" placeholder="00000000 チェーン" matInput [formControl]="templeteChain.form"
                [matAutocomplete]="templateAutoComplete">
              <mat-autocomplete #templateAutoComplete [displayWith]="autoCompleteDisplay.bind(this)">
                <mat-option *ngFor="let option of templeteChain.filter | async" [value]="option.chainCd">
                  {{ option.chainCd }} {{ option.chainName }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="button-field">
            <button mat-raised-button color="primary" class="download-button" (click)="clickedDownLoadTemplate()">
              <i class="fa fa-download"></i>
              テンプレートをダウンロード
            </button>
          </div>
        </div>

        <!-- 商品マスタダウンロード -->
        <div class="goods-master-field">
          <div>
            <h5 class="heading">条件を指定して商品マスタをダウンロードする</h5>
          </div>
          <div class="download-caution px-3">
            ※「絞り込み条件」のいずれかを必ず指定してください。データ量が多いと、ダウンロードは失敗します。
          </div>
          <div class="pt-3 px-3">
            <mat-accordion multi>
              <mat-expansion-panel class="except-bottom-border">
                <mat-expansion-panel-header>
                  <mat-panel-title class="condition-title">
                    絞り込み条件
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container>
                  <div class="search-conditions-field">
                    <div *ngFor="let item of displaySearchConditions" class="item-field">
                      <div *ngIf="item.visible" class="item">
                        <div style="margin-bottom: 0.5rem;">
                          {{item.displayName}}
                        </div>
                        <div style="width: 100%;">
                          <!-- テキスト型条件欄 -->
                          <ng-container *ngIf="item.type === 'txt'" style="width: 100%;">
                            <mat-form-field floatLabel="never" appearance="fill" style="width: 100%;">
                              <input type="text" matInput [formControl]="item.form">
                            </mat-form-field>
                          </ng-container>
                          <!-- 列挙型条件欄 -->
                          <ng-container *ngIf="item.type === 'enum'">
                            <mat-form-field appearance="fill" style="width: 100%;">
                              <!-- <mat-label>複数選択可能</mat-label> -->
                              <mat-select [formControl]="item.form"
                                (selectionChange)="changedEnumCondition($event, item)" multiple placeholder="複数選択可能">
                                <mat-option *ngFor="let option of item.displayOptions" [value]="option.key">
                                  {{option.label}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </ng-container>
                          <!-- ブランク -->
                          <div *ngIf="!item.type"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </mat-expansion-panel>
              <mat-expansion-panel class="panel-border">
                <mat-expansion-panel-header>
                  <mat-panel-title class="condition-title">
                    表示条件
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="display-conditions-field">
                  <div class="d-flex flex-row w-100 align-items-center">
                    <mat-checkbox color="primary" [(ngModel)]="goodsMasterChain.include">
                      商品グループ(チェーン個別)を含める
                    </mat-checkbox>
                    <mat-form-field [ngClass]="{ 'prevent-touch': !goodsMasterChain.include }" floatLabel="never"
                      appearance="fill" style="width: 40%; margin-left: 5rem;">
                      <input type="text" placeholder="00000000 チェーン" matInput [formControl]="goodsMasterChain.form"
                        [matAutocomplete]="goodsMasterAutoComplete">
                      <mat-autocomplete #goodsMasterAutoComplete [displayWith]="autoCompleteDisplay.bind(this)">
                        <mat-option *ngFor="let option of goodsMasterChain.filter | async" [value]="option.chainCd">
                          {{ option.chainCd }} {{ option.chainName }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </div>
                  <mat-checkbox [(ngModel)]="includeCode" color="primary">
                    コード類を含める
                  </mat-checkbox>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <div class="button-field">
            <button mat-raised-button color="primary" class="download-button" (click)="clickedGoodsMasterDownload()">
              <i class="fa fa-download"></i>
              商品マスタをダウンロード
            </button>
          </div>
        </div>
        <!-- 申請 -->
        <div *ngIf="scopes?.showButtonApplyGoodsMaster || scopes?.showButtonCheckApplyGoodsMaster" class="apply-field">
          <div>
            <h5 class="heading">商品マスタの新規変更を申請する</h5>
          </div>
          <div class="button-field">
            <button *ngIf="scopes?.showButtonApplyGoodsMaster" mat-raised-button color="accent"
              class="download-button mx-1" (click)="applyOnServicenow()">
              アップロード申請
            </button>
            <button *ngIf="scopes?.showButtonCheckApplyGoodsMaster" mat-raised-button color="primary"
              class="download-button mx-1" (click)="openApplyList()">
              申請状況確認
            </button>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab *ngIf="scopes?.showTabUploadGoodsMaster" label="アップロード" class="label">
      <!-- チェーン選択欄 -->
      <div class="select-chain-container">
        <mat-checkbox color="primary" [(ngModel)]="uploadChain.include">
          チェーン個別商品属性を含める
        </mat-checkbox>
        <mat-form-field [ngClass]="{ 'prevent-touch': !uploadChain.include }" floatLabel="never" appearance="fill"
          style="width: 40%; margin-left: 5rem;">
          <input type="text" placeholder="00000000 チェーン" matInput [formControl]="uploadChain.form"
            [matAutocomplete]="uploadAutoComplete">
          <mat-autocomplete #uploadAutoComplete [displayWith]="autoCompleteDisplay.bind(this)">
            <mat-option *ngFor="let option of uploadChain.filter | async" [value]="option.chainCd">
              {{ option.chainCd }} {{ option.chainName }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>

      <!-- アップロード -->
      <div class="upload-field">
        <div class="message-title">メッセージ</div>
        <div class="upload-control-filed">
          <app-message-field class="message-field"></app-message-field>
          <app-upload-file class="file-drop-field" (fileEvent)="setFile($event)"></app-upload-file>
        </div>
        <div class="upload-button">
          <button mat-raised-button (click)="uploadFile()">
            <i class="fa fa-upload"> アップロード</i>
          </button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>

<!-- 非表示ダウンロードリンク -->
<a #downloadLinkRef style="display: none">非表示リンク</a>

export type LogSeverityType = 'Info' | 'Warning' | 'Error';

export class LogModel {
    /**
     * ログテキスト
     */
    public LogText:string = '';
    /**
     * ログ重要度【Info/Warning/Error】
     */
    public LogSeverity: LogSeverityType;
}


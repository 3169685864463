import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ElementRef, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileDownloadService } from 'src/app/services/file-download/file-download.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RequestForChainService {

  constructor(
    private http: HttpClient,
    private dl: FileDownloadService
  ) { }

  public downloadRequestFile(dlname: string, el: ElementRef) {
    this.dl.get('api/request-for-chain', { 'organization': environment.organization }, dlname, el);
  }

  public uploadRequest(file: FormData) {
    const httpOptions = {
      headers: new HttpHeaders({
        'contet-type': 'multipart/form-data',
      })
    };
    return this.http.post(environment.apiEndPoint + 'api/request-for-chain/upload-request', file, httpOptions) as Observable<any>;
  }
}

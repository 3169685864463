import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { ElementRef, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FileDownloadService } from 'src/app/services/file-download/file-download.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SolveUnmatchedGoodsService {
  private httpOptions = {
    headers: new HttpHeaders({
      'contet-type': 'multipart/form-data',
    })
  };

  constructor(
    private http: HttpClient,
    private dl: FileDownloadService
  ) { }

  public uploadUnmatchedGoodsList(file: FormData) {
    return this.http.post(environment.apiEndPoint + 'api/solve-unmatched-goods/upload-list', file, this.httpOptions) as Observable<any>;
  }

  public downloadGoodsList(paramFileName: string, dlFileName, el: ElementRef) {
    this.dl.get('api/solve-unmatched-goods/download-list', { 'fileName': paramFileName }, dlFileName, el);
  }

  public checkUnmatchedGoodsListExist(param: any): Observable<any> {
    return this.http.post(environment.apiEndPoint + 'api/solve-unmatched-goods/check-unmatched-list-exist', param) as Observable<any>;
  }

  public downloadUnmatchedGoodsList(param: any, dlname: string, el: ElementRef) {
    this.dl.post('api/solve-unmatched-goods/download-unmatched-list', param, dlname, el);
  }

  /**
   * 画面初期化処理
   */
  public initialize(organization: string): Observable<any> {
    const httpOptions: any = {
      params: { 'organization': organization }
    };
    return this.http.get(environment.apiEndPoint + 'api/solve-unmatched-goods/initialize', httpOptions) as Observable<any>;
  }
}

import { Component, Inject, OnInit } from '@angular/core';
import { AppAuthService } from 'src/app/services/auth/app-auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(private auth: AppAuthService) {}

  ngOnInit(): void {
    this.auth.handleRedirect();
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute  } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
  /* エラーメッセージ */
  public errorMessage = '';
  /* エラーメッセージリスト */
  private messageList = { 
    500: 'システムでエラーが発生しました',
    403: '認証エラーが発生しました' };

  @BlockUI() blockUI!: NgBlockUI;

  /**
   * コンストラクタ
   */
  constructor(private router: ActivatedRoute ) {
    this.router.queryParams.subscribe(params => {
      if(params['errorCode']){
        this.errorMessage = this.messageList[params['errorCode']];
      }
      else{
        this.errorMessage = 'システムでエラーが発生しました';
      }
    })
  }

  /**
   * 初期化処理
   */
  ngOnInit(): void {
    this.blockUI.reset();
  }
}

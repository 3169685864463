<mat-list>
  <mat-list-item *ngFor="let item of messageList" class="list-item">
    <div class="time">
      {{item.processDateTime}}
    </div>
    <div class="message" [ngClass]="{'error': item.errorCount > 0, 'warning': item.errorCount === 0 && item.warningCount > 0}">
      ファイルアップロードが完了しました。（エラー:{{item.errorCount}}件／警告:{{item.warningCount}}件／情報:{{item.infoCount}}件）
    </div>
  </mat-list-item>
</mat-list>

import { StoreUnmatchedComponent } from './pages/store-unmatched/store-unmatched.component';
import { StoreMasterComponent } from './pages/store-master/store-master.component';
import { IndividualAuthorityComponent } from './pages/individual-authority/individual-authority.component';
import { ChainMainChargeComponent } from './pages/chain-main-charge/chain-main-charge.component';
import { RequestForChainComponent } from './pages/request-for-chain/request-for-chain.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { AuthoritySelectorComponent } from './pages/authority-selector/authority-selector.component';
import { ConfigureChainCalendarComponent } from './pages/configure-chain-calendar/configure-chain-calendar.component';
import { ConvertGoodsAttributeComponent } from './pages/convert-goods-attribute/convert-goods-attribute.component';
import { ErrorComponent } from './pages/error/error/error.component';
import { MaintenanceComponent } from './pages/error/maintenance/maintenance.component';
import { NotFoundComponent } from './pages/error/not-found/not-found.component';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { MainContainerComponent } from './pages/main-container/main-container.component';
import { RegisterGoodsComponent } from './pages/register-goods/register-goods.component';
import { RegisterNewGoodsComponent } from './pages/register-new-goods/register-new-goods.component';
import { RootComponent } from './pages/root/root.component';
import { SetMaintenanceControlsComponent } from './pages/set-maintenance-controls/set-maintenance-controls.component';
import { SetReferenceControlsComponent } from './pages/set-reference-controls/set-reference-controls.component';
import { SolveUnmatchedGoodsComponent } from './pages/solve-unmatched-goods/solve-unmatched-goods.component';
import { TestComponent } from './pages/test/test.component';
import { UpdateCalendarComponent } from './pages/update-calendar/update-calendar.component';
import { UpdateChainsComponent } from './pages/update-chains/update-chains.component';
import { UpdateGoodsComponent } from './pages/update-goods/update-goods.component';
import { LoggedInGuard } from './services/guard/logged-in.guard';
import { environment } from 'src/environments/environment';

const createRoutes = (): Routes => {
  const pageChildren: Routes = [
    { path: 'home', component: HomeComponent, canActivate: [LoggedInGuard] },
    { path: 'configure-chain-calendar', component: ConfigureChainCalendarComponent, canActivate: [LoggedInGuard] },
    { path: 'convert-goods-attribute', component: ConvertGoodsAttributeComponent, canActivate: [LoggedInGuard] },
    { path: 'register-goods', component: RegisterGoodsComponent, canActivate: [LoggedInGuard] },
    { path: 'register-new-goods', component: RegisterNewGoodsComponent, canActivate: [LoggedInGuard] },
    { path: 'set-maintenance-controls', component: SetMaintenanceControlsComponent, canActivate: [LoggedInGuard] },
    { path: 'set-reference-controls', component: SetReferenceControlsComponent, canActivate: [LoggedInGuard] },
    { path: 'solve-unmatched-goods', component: SolveUnmatchedGoodsComponent, canActivate: [LoggedInGuard] },
    { path: 'solve-unmatched-goods/:chainCd', component: SolveUnmatchedGoodsComponent, canActivate: [LoggedInGuard] },
    { path: 'update-calendar', component: UpdateCalendarComponent, canActivate: [LoggedInGuard] },
    { path: 'update-goods', component: UpdateGoodsComponent, canActivate: [LoggedInGuard] },
    { path: 'store-master', component: StoreMasterComponent, canActivate: [LoggedInGuard] },
    { path: 'store-unmatched', component: StoreUnmatchedComponent, canActivate: [LoggedInGuard] },
    { path: 'store-master/:chainCd', component: StoreMasterComponent, canActivate: [LoggedInGuard] },
    { path: 'store-unmatched/:chainCd', component: StoreUnmatchedComponent, canActivate: [LoggedInGuard] },
    { path: 'update-chains', component: UpdateChainsComponent, canActivate: [LoggedInGuard] },
    { path: 'authority-selector', component: AuthoritySelectorComponent, canActivate: [LoggedInGuard] },
    { path: 'request-for-chain', component: RequestForChainComponent, canActivate: [LoggedInGuard] },
    { path: 'chain-main-charge', component: ChainMainChargeComponent, canActivate: [LoggedInGuard] },
    { path: 'individual-authority', component: IndividualAuthorityComponent, canActivate: [LoggedInGuard] },
  ];

  // 開発環境ならテストページを使えるようにする
  if (environment.production === false) {
    pageChildren.push({ path: 'test', component: TestComponent, canActivate: [LoggedInGuard] });
  }

  return [
    { path: '', component: RootComponent},
    { path: 'login', component: LoginComponent },
    { path: 'error', component: ErrorComponent },
    { path: 'maintenance', component: MaintenanceComponent },
    { path: 'pages', component: MainContainerComponent, children: pageChildren},
    { path: '**', component: NotFoundComponent }
  ];
}

@NgModule({
  imports: [RouterModule.forRoot(createRoutes(), {
    scrollPositionRestoration: 'top',
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AuthorityService } from 'src/app/services/authority/authority.service';
import { ScopeViewModel } from 'src/app/services/authority/authority.service.viewmodel';
import { RouterService } from 'src/app/services/router/router.service';
import { MaintenanceMenuService } from 'src/app/shared/maintenance-menu/maintenance-menu.service';
import { MessageFieldComponent } from 'src/app/shared/message-field/message-field.component';
import { UploadResultModel } from 'src/app/shared/message-field/message-field.viewmodel';
import { TableauEmbedComponent } from 'src/app/shared/tableau-embed/tableau-embed.component';
import { environment } from 'src/environments/environment';
import { UpdateStoresService } from './update-stores.service';

type ChainSelectionTypes = 'charge_all' | 'charge_chain' | 'all' | 'chain';
/** チェーン選択の一覧 */
class ChainSelectionItem {
  public name: string;
  public value: ChainSelectionTypes;
  public charge: boolean;
  public selectable: boolean;
}

@Component({
  selector: 'app-store-master',
  templateUrl: './store-master.component.html',
  styleUrls: ['./store-master.component.scss']
})
export class StoreMasterComponent implements OnInit {
  /* 店舗情報ファイル */
  private storesFile: NgxFileDropEntry;
  /* 拡張子 */
  public allowedExtension = '.xlsx';
  /* メッセージフィールド */
  @ViewChild('messageField') private messageField: MessageFieldComponent;
  @ViewChild('tableauStore') private tableauStore: TableauEmbedComponent;
  /* チェーンリスト */
  public chainList = [];
  /* フィルタリングされたチェーンリスト */
  public filteredChainList: Observable<{}>;
  /* チェーン選択オートコンプリートフォーム */
  public chainListControl = new FormControl();

  /** チェーン選択タイプ */
  public chainSelectionTypes: ChainSelectionItem[] = [
    { name: '全ての担当チェーンを表示', value: 'charge_all', charge: true, selectable: false },
    { name: '担当チェーンを選択', value: 'charge_chain', charge: true, selectable: true },
    { name: '全てチェーンを表示', value: 'all', charge: false, selectable: false },
    { name: 'チェーンを選択', value: 'chain', charge: false, selectable: true },
  ];
  public frmChainSelectionType: FormControl = new FormControl('');
  /** チェーンの選択可否 */
  public chainSelectable = true;

  /** 権限スコープ */
  public scopes: ScopeViewModel;

  constructor(
    public maintenanceMenuService: MaintenanceMenuService,
    private routerService: RouterService,
    private toastr: ToastrService,
    private updateStoresService: UpdateStoresService,
    private authorityService: AuthorityService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getScopes();
    this.routerService.setPreviousPage();
  }

  private async getScopes() {
    this.scopes = await this.authorityService.scopes();
  }

  /**
   * 店舗情報ファイル取得
   */
  public setStoresFile(file: NgxFileDropEntry) {
    // エクセルファイル未選択
    if (file !== null && !file.relativePath.endsWith(this.allowedExtension)) {
      this.toastr.error('Excelファイル以外が選択されています。ファイルを再選択してください', 'Excelファイル非選択');
      return;
    }
    this.storesFile = file;
  }

  /**
   * 店舗情報ファイルアップロード
   */
  public async uploadStoresFile() {
    // ファイル未選択
    if (!this.storesFile || !this.storesFile.fileEntry.isFile) {
      this.toastr.error('店舗情報ファイルが選択されていません。', '店舗情報ファイル未選択');
      return;
    }

    const formData = new FormData();
    formData.append('organization', environment.organization);
    const fileEntry = this.storesFile.fileEntry as FileSystemFileEntry;
    fileEntry.file((file: File) => {
      formData.append('file', file, this.storesFile.relativePath);
      // 店舗情報ファイル取り込みAPI呼び出し
      this.updateStoresService.uploadStoreMaster(formData).subscribe((res: UploadResultModel) => {
        this.messageField.pushMessage(res);
        // エラーあり
        if (res.errorCount > 0) {
          this.toastr.error('店舗情報のアップロードに失敗しました', 'アップロード失敗');
        }
        // 警告あり
        else if (res.warningCount > 0) {
          this.toastr.warning('店舗情報のアップロードで警告があります', 'アップロード警告');
        }
        // 正常終了
        else {
          this.toastr.info('店舗情報のアップロードが完了しました', 'アップロード完了');
        }
      });
    });
  }

  /**
   * オートコンプリート表示処理
   */
  public autoCompleteDisplay(value: any) {
    if (!value) {
      return '';
    }
    const chain = this.chainList.find(x => x.chainCd === value);
    return `${chain.chainCd} ${chain.chainName}`;
  }

  public clearChainSelection(e: Event) {
    this.chainListControl.reset();
    e.stopPropagation();
  }

  /**
   * フィルタリングする文字列
   * @param filteringStr オートコンプリートフィルタリング
   */
  private autoCompleteFilter(filteringStr: any) {
    if (filteringStr) {
      const filteredKeys = filteringStr.toLowerCase();
      const filtered = this.chainList.filter(it => it.chainName.toLowerCase().includes(filteredKeys) || it.chainCd.includes(filteredKeys));
      return filtered;
    } else {
      return this.chainList;
    }
  }

}

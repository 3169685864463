import { CacheLocation } from '@auth0/auth0-spa-js';
import { environment_org } from './environment_organization';

const SERVICENOW_HOST = 'https://asahighproddev1.service-now.com/esc/';

export const environment = {
  production: true,
  cloudLogging: true,
  debugMenu: true,

  apiEndPoint: 'https://g23-newpos-stg-master-back-6z2ymjzdsa-an.a.run.app/',
  tableauHost: 'https://hd-keieidashboard-1766.biz',
  tableView: {
    newGoods: environment_org.tableau_view.new_goods.stg,
  },

  organization: environment_org.organization,

  servicenow: {
    host: SERVICENOW_HOST,
    masterMaintenanceURL: SERVICENOW_HOST + environment_org.servicenow.masterMaintenanceURL,
    convertGoodsAttributeURL: SERVICENOW_HOST + environment_org.servicenow.convertGoodsAttributeURL,
    solveUnmatchedGoodsURL: SERVICENOW_HOST + environment_org.servicenow.solveUnmatchedGoodsURL,
    solveUnmatchedStoresURL: SERVICENOW_HOST + environment_org.servicenow.solveUnmatchedStoresURL,
    storeMasterMaintenanceURL: SERVICENOW_HOST + environment_org.servicenow.storeMasterMaintenanceURL,
    myRequestsURL: SERVICENOW_HOST + '?id=my_requests',
    individualAuthorityURL: SERVICENOW_HOST + environment_org.servicenow.individualAuthorityURL,
    requestChainStaffURL: SERVICENOW_HOST + environment_org.servicenow.requestChainStaffURL,
    requestForChainURL: SERVICENOW_HOST + environment_org.servicenow.requestForChainURL,
    generalURL: SERVICENOW_HOST + environment_org.servicenow.generalURL,
    POSRequestURL: SERVICENOW_HOST + environment_org.servicenow.POSRequestURL,
    recoveryRequestURL: SERVICENOW_HOST + environment_org.servicenow.recoveryRequestURL,
    inquiryURL: SERVICENOW_HOST + environment_org.servicenow.inquiryURL
  },

  auth0: {
    domain: 'asahig-dev.jp.auth0.com',
    clientId: 'L7Tx8RmbNhrPknHdgSOztxlimg0Bvj07',
    useRefreshTokens: true,
    skipRedirectCallback: true,
    redirectUri: environment_org.auht0_redirectUri.stg,
    cacheLocation: 'localstorage' as CacheLocation,
    audience: 'asahig-api'
  },

  appAuth0: {
    keyUserId: 'https://asahig-dev.jp.com/userid'
  }
};

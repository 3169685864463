<div>
  <button mat-raised-button>Basic</button>
  <button mat-raised-button color="primary">Primary</button>
  <button mat-raised-button color="accent">Accent</button>
  <button mat-raised-button color="warn">Warn</button>
  <button mat-raised-button disabled>Disabled</button>
  <span class="me-2">bootstrap</span><span>test</span>
</div>
<button mat-raised-button (click)="testToastr()">トースターテスト</button>
<button mat-raised-button (click)="testWriteLog()">ログ出力テスト</button>
<button mat-raised-button (click)="testDownload()">ダウンロードテスト</button>
<!-- 非表示ダウンロードリンク -->
<a #downloadLinkRef style="display: none">非表示リンク</a>
